import Vue from "vue";
import VueCookies from "vue-cookies";

import { LocalizationPlugin } from "@/plugins/localization";
import { AxiosPlugin } from "@/plugins/axios";

Vue.use(VueCookies);
Vue.use(LocalizationPlugin);
Vue.use(AxiosPlugin);
const v = new Vue();

export default {
  //@ts-ignore
  search({ commit }, value) {
    commit("setProperties", []);
    //@ts-ignore
    v.$axios
      .get("/api/properties/search?search=" + value)
      //@ts-ignore
      .then((res) => {
        //@ts-ignore
        commit("setProperties", res.data);
        //@ts-ignore
      })
      //@ts-ignore
      .catch((res) => {
        console.log(res.data);
      });
  },
};
