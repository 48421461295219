export default {
  //@ts-ignore
  options: (state) => {
    //@ts-ignore
    return state.found.map((el) => {
      return {
        name: el.translations[0].title,
        value: el.id,
        data: el,
        img: el.images.length > 0 ? el.images[0] : null,
      };
    });
  },
};
