export default {
  back: "back to all projects",
  modify: "Edit a project",
  general_infos: "General Information",
  properties: "Properties",
  no_project: "No project",
  notifications: {
    updated: "Project updated",
    created: "Project created",
    saved: "Project saved",
  },
};
