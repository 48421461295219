<template>
  <div class="checkbox" @click="toggleValue">
    <transition name="fade">
      <svg
        v-show="value"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 26 26"
        enable-background="new 0 0 26 26"
        width="512px"
        height="512px"
      >
        <path
          d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z"
        />
      </svg>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      type: [Boolean, Number],
      required: true,
    },
  },
  methods: {
    toggleValue() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  width: 80%;
  height: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
