import store from "@/store";
import { Capability } from "@/types";
import userCan from "@/helpers/userCan";
import router from "@/router";

export default () => {
  const unsubscribe = store.subscribe((mutation, state) => {
    if (mutation.type === "setupProfile") {
      const routeName: string = store.state.global.firstLoadedRoute.name;
      const routeCapabilities: Array<Capability> | undefined = router.resolve({
        name: routeName,
      })?.resolved?.meta?.capabilities;
      const userCapabilities: Array<Capability> = store.state.global.user.capabilities;
      if (routeCapabilities && userCapabilities && userCapabilities.length) {
        if (!userCan(userCapabilities, routeCapabilities)) {
          console.log("First load after user profile fetched - authorized to view this route");
          router.push({ name: "unauthorized" });
        } else {
          console.log("First load after user profile fetched - user not authorized to view this route");
          const route = store.state.global.firstLoadedRoute;
          // Don't push if already on the route
          if ( route.path !== router.currentRoute.path ) {
            router.push({path: route.path});
          }
        }
      }
      unsubscribe();
    }
  });
};
