<template>
  <div class="inner-wrapper">
    <form @submit.prevent="saveClient">
      <div class="input-box">
        <input type="text" v-model="client.first_name" :placeholder="$t('overall.first_name')" />
      </div>
      <div class="input-box">
        <input type="text" v-model="client.last_name" :placeholder="$t('overall.last_name')" />
      </div>
      <div class="flex main-actions" style="margin-top: 20px">
        <button class="cta save align-right">{{ $t("clients.create") }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import notifBus from "@/bus/notification-bus";
import modalBus from "@/bus/modal-bus";
import mixCustomerCore from "@/mixins/customerCore";

export default Vue.extend({
  mixins: [mixCustomerCore],
  data() {
    return {
      client: {
        first_name: "",
        last_name: "",
        type: "private",
      },
    };
  },
  methods: {
    saveClient() {
      const collaborators = [];
      collaborators.push(this.user);

      this.$axios
        .post("/api/clients", { client: this.client, collaborators })
        .then((res) => {
          modalBus.$emit("toggle");
          this.$router.push({ name: "edit-client", params: { token: res.data } });
        })
        .catch((error) => {
          console.log("error", error.response);
          let message = "Something went wrong";
          if (error.response) {
            if (error.response.data && error.response.data.message) {
              message = `Error ${error.response.status} - "${error.response.data.message}"`;
            }
          }
          notifBus.$emit("show", {
            status: "error",
            message,
          });
        });
    },
  },
});
</script>
