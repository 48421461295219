export default {
  month1: "Enero",
  month2: "Febrero",
  month3: "Marzo",
  month4: "Abril",
  month5: "Mayo",
  month6: "Junio",
  month7: "Julio",
  month8: "Agosto",
  month9: "Setiembre",
  month10: "Octubre",
  month11: "Noviembre",
  month12: "Diciembre",

  day1: "Lunes",
  day2: "Martes",
  day3: "Miércoles",
  day4: "Jueves",
  day5: "Viernes",
  day6: "Sabado",
  day7: "Domingo",

  today: "Hoy",
  day: "dia",
  week: "semana",
  month: "mes",
  year: "año",

  filter: "Filtrar",
  filters: "Filtros",
  edit: "Editar",
  create: "Crear",
  new: "Nuevo",
  search: "Indique su búsqueda",
  "back-to-the-list": "Vuelver al listado",
  results: "resultados",
  per_page: "por página",
  "create-new": "Crear nuevo",
  save: "Guardar",
  cancel: "Cancelar",
  add: "Añadir",
  delete: "Borrar",
  actions: "Acciones",
  reinitialize: "Restablecer",
  import: "Importar",
  export: "Exportar",
  send: "Enviar",
  print: "Imprimir",
  confirm: "Confirmar",
  confirmation: "Confirmación",
  pending: "En espera",
  no_result: "Sin resultados",

  "are-you-sure": "¿Está seguro de esta acción?",
  "not-specified": "No especificado",
  yes: "Si",
  no: "No",

  east: "Este",
  west: "Oeste",
  north: "Norte",
  south: "Sur",

  languages: "Idiomas",
  french: "Fancés",
  english: "Inglés",
  spanish: "Español",
  german: "Alemano",
  italian: "Italiano",
  dutch: "Holandés",
  russian: "Ruso",
  swedish: "Sueco",
  norwegian: "Noruegés",
  bulgarian: "Bulgaro",
  polish: "Polaco",

  country: "País",
  france: "Francia",
  belgium: "Bélgica",
  luxembourg: "Luxemburgo",
  england: "Inglaterra",
  portugal: "Portugal",
  italy: "Itália",
  spain: "España",
  germany: "Alemania",
  netherlands: "Holanda",
  russia: "Rusia",
  sweden: "Suecia",
  norway: "Noruega",

  admin: "Administrador",
  agent: "Agente",

  name: "Nombre",
  last_name: "Apelido",
  first_name: "Nombre",
  reference: "Referencia",
  company: "Empresa",
  birthday: "Fecha de nascimiento",
  client_number: "N° de cliente",
  status: "Estado",
  email: "Email",
  password: "Contraseña",
  phone: "Teléfono",
  id_number: "NIE",
  sex: "Sexo",
  man: "Hombre",
  woman: "Mujer",
  address: "Dirección",
  location: "Ubicación",
  street: "Calle",
  number: "Numero",
  city: "Ciudad",
  postcode: "Codigo Postal",
  optional: "Opcional",
  tasks: "Tareas",
  login: "Conectarse",
  logout: "Desconectarse",
  source: "Fuente",

  photos: "fotos",
  dashboard: "Panel administrador",
  calendar: "Calendario",
  "real-estate": "Inmobiliária",
  clients: "Clientes",
  users: "Utilizadores",
  editor: "Editor",
  settings: "Configuraciones",
  analytics: "Estadísticas",
  library: "Biblioteca",
  note: "Nota",
  title: "Titulo",
  previous: "Anterior",
  next: "Seguiente",
  last_deal: "Último deal",
  select_all: "Elegir todo",

  others: "Otros",

  "page-home": "Inicio",
  "page-about": "Sobre nosotros",
  "page-services": "Servicios",
  "page-properties": "Propiedades",
  "page-sell-house": "Vender sur casa",
  "page-rent-house": "Alquilar su cada",
  "page-team": "Equipo",
  "page-contact": "Contacto",
  "page-terms": "Termos y Condiciones",
  "page-privacy": "Poliítica de Privacidad",
  "page-clients": "Clientes",
  "page-agencies": "Agencias",
  "page-agents": "Agentes",
  "page-settings": "Configuraciones",
};
