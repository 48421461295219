import Vue from "vue";

export default new Vue({
  methods: {
    sendAndClose(data?: { emit?: { name: string; data?: {} } }) {
      this.$emit("toggle");
      this.$emit("send-value", data);
      if (data && data.emit !== undefined) {
        this.$emit(data.emit.name, data.emit.data);
      }
      this.$emit("clear");
    },
  },
});
