export interface User {
  firstName: string;
  lastName: string;
  twitterHandle?: string;
  location: Location;
}

export interface Location {
  city: string;
  state: string;
}

export const AccountCapabilityCanSeeSettings = "can_see_settings";
export const AccountCapabilityCanSeeBuilders = "can_see_builders";
export const AccountCapabilityCanSeeUsers = "can_see_users";
export const AccountCapabilityCanSeeNews = "can_see_news";
export const AccountCapabilityCanSeeProperties = "can_see_properties";
export const AccountCapabilityCanSeeProjects = "can_see_projects";
export const AccountCapabilityCanSeeOwnClients = "can_see_own_clients";
export const AccountCapabilityCanSeeSharedClients = "can_see_shared_clients";
export const AccountCapabilityCanSeeAllClients = "can_see_all_clients";
export const AccountCapabilityCanSeeOwnAgenda = "can_see_own_agenda";
export const AccountCapabilityCanSeeAgendaFromAll = "can_see_agenda_from_all";
export const AccountCapabilityCanDeleteThings = "can_delete_things";
export const AccountCapabilityCanDuplicateProperties = "can_duplicate_properties";
export const AccountCapabilityCanSeeBlog = "can_see_blog";

const CapabilitiesNames = [
  AccountCapabilityCanSeeSettings,
  AccountCapabilityCanSeeBuilders,
  AccountCapabilityCanSeeUsers,
  AccountCapabilityCanSeeNews,
  AccountCapabilityCanSeeProperties,
  AccountCapabilityCanSeeProjects,
  AccountCapabilityCanSeeOwnClients,
  AccountCapabilityCanSeeSharedClients,
  AccountCapabilityCanSeeAllClients,
  AccountCapabilityCanSeeOwnAgenda,
  AccountCapabilityCanSeeAgendaFromAll,
  AccountCapabilityCanDeleteThings,
  AccountCapabilityCanSeeBlog,
  AccountCapabilityCanDuplicateProperties,
] as const;

export type Capability = typeof CapabilitiesNames[number];

export const clientsRoutesCapabilities: Array<Capability> = [
  AccountCapabilityCanSeeOwnClients,
  AccountCapabilityCanSeeSharedClients,
  AccountCapabilityCanSeeAllClients,
];

export const usersRoutesCapabilities: Array<Capability> = [AccountCapabilityCanSeeUsers];

export const settingsRoutesCapabilities: Array<Capability> = [AccountCapabilityCanSeeSettings];

export const buildersRoutesCapabilities: Array<Capability> = [AccountCapabilityCanSeeBuilders];

export const projectsRoutesCapabilities: Array<Capability> = [AccountCapabilityCanSeeProjects];

export const newsRoutesCapabilities: Array<Capability> = [AccountCapabilityCanSeeNews];

export const jobsRoutesCapabilities: Array<Capability> = [AccountCapabilityCanSeeNews];

export const propertiesRoutesCapabilities: Array<Capability> = [AccountCapabilityCanSeeProperties];

export const blogRoutesCapabilities: Array<Capability> = [AccountCapabilityCanSeeBlog];

export const agendaRoutesCapabilities: Array<Capability> = [
  AccountCapabilityCanSeeOwnAgenda,
  AccountCapabilityCanSeeAgendaFromAll,
];
