<template>
  <div class="range-picker" :class="{ 'with-inputs': inputs }">
    <div class="side-input input-from">
      <input type="number" :value="from" @input="$emit('input', { from: parseInt($event.target.value), to })" />
    </div>
    <div class="slider">
      <input
        class="range"
        type="range"
        :value="from"
        @input="$emit('input', { from: parseInt($event.target.value), to })"
        :min="min"
        :max="max"
        :step="step"
      />
      <span class="link-points" :style="styleLinkBar"></span>
      <input
        class="range"
        type="range"
        :value="to"
        @input="$emit('input', { from, to: parseInt($event.target.value) })"
        :min="min"
        :max="max"
        :step="step"
      />
      <div class="flex labels" v-if="display">
        <span v-for="i in numberDisplays" :key="'display-' + i">{{ (i - 1) * display }}</span>
      </div>
    </div>
    <div class="side-input input-to">
      <input type="number" :value="to" @input="$emit('input', { from, to: parseInt($event.target.value) })" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "RangePicker",
  components: {},
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    display: {
      type: Number,
      default: null,
      required: false,
    },
    inputs: {
      type: Boolean,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      from: 0,
      to: 0,
    };
  },
  computed: {
    numberDisplays() {
      return Math.floor(this.max / this.display) + 1;
    },
    styleLinkBar() {
      let leftPosition = (this.from / this.max) * 100,
        width = ((this.to - this.from) / this.max) * 100;

      return {
        width: width + "%",
        left: leftPosition + "%",
      };
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.from > val.to) {
          this.$emit("input", { from: val.to, to: val.to });
        }
        this.from = val.from;
        this.to = val.to;
      },
    },
  },
  methods: {},
});
</script>
