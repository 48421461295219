<template>
  <form autocomplete="off" class="autocomplete" @submit.prevent="$emit('save', options[0])" v-click-outside:isOpen>
    <input :placeholder="placeholder" type="text" v-model="search" @keyup="searchIt()" @blur="$emit('blur')" />
    <ul class="options" v-if="isOpen">
      <!--<li v-if="isLoading">{{ $t("overall.loading") }}...</li>-->
      <template v-if="options.length > 0">
        <li v-for="r in options" :key="'result-' + r.value" v-html="r.name" @click="sendAndReset(r)"></li>
      </template>
      <li v-else-if="options.length < 1">{{ $t("overall.no_result") }}</li>
    </ul>
  </form>
</template>
<script>
export default {
  name: "Autocomplete",
  props: {
    value: {
      required: false,
      default: "",
    },
    options: {
      required: true,
    },
    clearOnSave: {
      default: false,
    },
    placeholder: {
      default: "",
    },
  },
  data() {
    return {
      search: "",
      isOpen: false,
      isLoading: false,
      timer: null,
      debounce: 300,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val === null) {
          this.isLoading = false;
        }
        this.search = val;
      },
    },
  },
  methods: {
    searchIt() {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.isOpen = true;
        this.isLoading = true;
        this.$emit("search", this.search);
      }, this.debounce);
    },
    sendAndReset(value) {
      this.isOpen = false;
      this.search = this.clearOnSave ? "" : value.name;
      this.$emit("save", value);
      this.$emit("input", value);
    },
  },
};
</script>
