<template>
  <div class="popup">
    <div class="box full-page">
      <header class="d-flex justify-content-between">
        <div class="content">
          <div class="flex">
            <ul class="menu">
              <li :class="{ active: tab === 'images' }">
                <button @click="tab = 'images'">{{ $t("properties.library.images") }}</button>
              </li>
              <li :class="{ active: tab === 'videos' }">
                <button @click="tab = 'videos'">{{ $t("properties.library.videos") }}</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="button" v-if="tab === 'images'">
          <label for="newPictures" class="cta">{{ $t("overall.import") }}</label>
          <input ref="myFiles" type="file" class="hide" id="newPictures" @change="uploadImages" multiple />
        </div>
      </header>
      <div v-show="tab === 'images'" class="img-list" @drop.prevent="addFiles" @dragover.prevent>
        <ul>
          <draggable v-model="images.ordered" @start="drag = true" @end="drag = false">
            <li class="el" v-for="(img, index) in images.ordered" :key="'img-' + index">
              <button class="delete-image-library" @click="deleteImage(img)">
                <crossSVG />
              </button>
              <button class="file-picker">
                <img :src="img" :alt="img" />
              </button>
            </li>
          </draggable>
          <li class="el" v-for="i in imagesLoading" :key="'loading-' + i"><div class="loader"></div></li>
          <li class="el" v-for="i in errors" :key="'error-' + i">Error</li>
        </ul>
        <div class="no-image-library" v-if="images.ordered.length < 1">
          <strong>{{ $t("properties.library.no_picture") }}</strong>
          <p>{{ $t("properties.library.drag_and_drop") }}</p>
        </div>
      </div>
      <div v-show="tab === 'videos'" class="videos">
        <div class="video flex" v-for="(v, i) in isVideosActive" :key="'video-' + i">
          <input
            type="text"
            :class="{ disabled: !isVideosActive[i] }"
            class="input-video-link not-input"
            placeholder="https://www.youtube.com/watch?v=ttWXhFsVNgc"
            v-model="videos[i]"
            :disabled="!isVideosActive[i]"
            @keydown.enter="isVideosActive.splice(i, 1, !isVideosActive[i])"
          />
          <button
            class="validate-video"
            v-if="isVideosActive[i]"
            @click="isVideosActive.splice(i, 1, !isVideosActive[i])"
          >
            <checkSVG />
          </button>
          <div class="flex" v-if="!isVideosActive[i]">
            <button class="edit-video" @click="isVideosActive.splice(i, 1, !isVideosActive[i])"><editSVG /></button>
            <button class="delete-video" @click="isVideosActive.splice(i, 1)"><deleteSVG /></button>
          </div>
        </div>
        <form class="video flex" @submit.prevent="addVideo">
          <input
            type="text"
            class="input-video-link not-input"
            placeholder="https://www.youtube.com/watch?v=ttWXhFsVNgc"
            v-model="currentVideo"
          />
          <button class="validate-video cta">{{ $t("overall.add") }}</button>
        </form>
      </div>
      <div class="actions">
        <button class="cta" @click="sendToProperty">{{ $t("overall.save") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import modalBus from "@/bus/modal-bus";
import crossSVG from "@/assets/img/cross.svg";
import checkSVG from "@/assets/img/check.svg";
import editSVG from "@/assets/img/edit.svg";
import deleteSVG from "@/assets/img/dustbin.svg";

export default {
  props: {
    data: Object,
  },
  components: {
    draggable,
    crossSVG,
    checkSVG,
    editSVG,
    deleteSVG,
  },
  data() {
    return {
      tab: "images",
      currentVideo: "",
      files: [],
      videos: [],
      isVideosActive: [],
      images: {
        ordered: [],
        add: [],
        delete: [],
        current: [],
      },
      imagesLoading: 0,
      errors: 0,
    };
  },
  methods: {
    addFiles(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.files.push(f);
      });
      this.uploadImages();
    },
    uploadImages() {
      let files = this.files.length ? [...this.files] : this.$refs.myFiles.files,
        numberOfFiles = files.length;

      this.files = [];
      for (let i = 0; i < numberOfFiles; ++i) {
        const formData = new FormData();
        this.imagesLoading++;
        formData.append("images[0]", files[i]);
        this.$axios
          .post("/app/library/create", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            response.data.forEach((el) => {
              this.imagesLoading--;
              el = this._global.rServer + "s" + el;
              this.images.add.push(el);
              this.images.ordered.push(el);
            });
          })
          .catch((e) => {
            this.imagesLoading--;
            this.errors++;
          });
      }
      this.$refs.myFiles.value = [];
    },
    addVideo() {
      if (this.currentVideo.trim() !== "") {
        this.videos.unshift(this.currentVideo);
        this.isVideosActive.unshift(false);
        this.currentVideo = "";
      }
    },
    deleteImage(imgToRemove) {
      let isTemp = this.images.add.findIndex((el) => imgToRemove === el);
      // is temp so provided by add
      isTemp !== -1 ? this.images.add.splice(isTemp, 1) : this.images.delete.push(imgToRemove);

      let imgIndex = this.images.ordered.findIndex((el) => imgToRemove === el);
      this.images.ordered.splice(imgIndex, 1);
    },
    sendToProperty() {
      modalBus.sendAndClose({
        emit: {
          name: "library-save",
          data: {
            images: this.images,
            videos: this.videos,
          },
        },
      });
    },
  },
  mounted() {
    this.images = this.data.images;
    this.videos = this.data.videos;
    for (let i = 0; i < this.videos.length; i++) {
      this.isVideosActive.push(false);
    }
  },
};
</script>
