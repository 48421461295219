<template>
  <div>
    <h2>{{ $t("settings.available_languages") }}</h2>
    <div class="flex languages-list">
      <draggable :value="lang" @input="$emit('input', $event)" @start="drag = true" @end="drag = false">
        <span v-for="l in lang" :key="l">
          {{ $t("overall.lang." + l) }}
          <crossSVG
            @click="
              $emit(
                'input',
                lang.filter((el) => l !== el),
              )
            "
        /></span>
      </draggable>
    </div>
    <MultiSelect
      :placeholder="$t('parameters.select_language')"
      :options="options"
      :value="lang"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";

export default Vue.extend({
  name: "Properties",
  props: ["lang", "options"],
  components: {
    draggable,
  },
  data() {
    return {
      languages: [],
    };
  },
});
</script>

<style></style>
