import Vue from "vue";
import VueCookies from "vue-cookies";

import { LocalizationPlugin } from "@/plugins/localization";
import { AxiosPlugin } from "@/plugins/axios";

Vue.use(VueCookies);
Vue.use(LocalizationPlugin);
Vue.use(AxiosPlugin);
const v = new Vue();
export default {
  //@ts-ignore
  array_to_select: (state) => (payload) => {
    //@ts-ignore
    return payload.map((el) => {
      return {
        //@ts-ignore
        name: v.$t(el),
        value: el,
      };
    });
  },

  //@ts-ignore
  getCurrency({ settings }) {
    switch (settings.currency) {
      case "eur":
        return "€";

      case "usd" || "aud" || "cad":
        return "$";

      case "gpb":
        return "£";

      case "jpy":
        return "¥";

      case "chf":
        return "Fr.";
    }
  },
};
