import Vue from "vue";
import VueCookies from "vue-cookies";

import { LocalizationPlugin } from "@/plugins/localization";
import { AxiosPlugin } from "@/plugins/axios";

Vue.use(VueCookies);
Vue.use(LocalizationPlugin);
Vue.use(AxiosPlugin);
const v = new Vue();
export default {
  //@ts-ignore
  setupLanguages({ commit, state }) {
    //@ts-ignore
    v.$axios.get("/api/settings").then((res) => {
      //@ts-ignore
      const response = res.data.overall.languages.map((el) => {
        return {
          //@ts-ignore
          name: v.$t("overall.lang." + el.lang),
          value: el.lang,
          data: el,
          order: el.order,
        };
      });
      //@ts-ignore
      commit("setLanguages", response);
      commit("setSettings", res.data.overall);
      //@ts-ignore
    });
  },
  //@ts-ignore
  setupProfile({ commit, state }, payload) {
    console.log("setup profile", payload);
    //@ts-ignore
    commit("setProfile", payload);
  },
  //@ts-ignore
  logout({ commit, state, dispatch }, payload) {
    console.log("Logout");
    commit("emptyUserObject");
    commit("TOGGLE_MENU", false);
    dispatch("agenda/resetEventsForLogout", {}, { root: true });
  },
};
