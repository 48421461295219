<template>
  <div class="autocomplete-address">
    <Autocomplete
      :placeholder="placeholder"
      :value="formatted_address"
      :options="locations"
      @save="setLocation"
      @search="search"
      :clearOnSave="clearOnSave"
      @blur="test"
    />
    <a
      class="view-map"
      :class="{ disabled: !(currentLocation && currentLocation.full_address) }"
      target="_blank"
      :href="googleMap"
    >
      <locationSVG />
    </a>
  </div>
</template>
<script>
const urlDetails = "api/locations/details/";
const urlLocation = "api/locations/";

import locationSVG from "@/assets/img/location.svg";
export default {
  name: "AddressAutocomplete",
  props: ["value", "options", "clearOnSave", "placeholder"],
  components: {
    locationSVG,
  },
  data() {
    return {
      locations: [],
      currentLocation: null,
      formatted_address: null,
    };
  },
  computed: {
    googleMap() {
      return "https://www.google.com/maps/place/" + this.formatted_address;
    },
  },
  methods: {
    test() {
      if (!this.formatted_address) {
        this.$emit("input", {
          id: null,
          country: null,
          full_address: null,
          region: null,
          province: null,
          postcode: null,
          zone: null,
          city: null,
          street: null,
          number: null,
          latitude: null,
          longitude: null,
        });
      }
    },
    setLocation(location = null) {
      if (location) {
        this.locations = [];
        this.$axios.get(urlDetails + location.value).then((res) => {
          this.currentLocation = res.data;
          this.$emit("input", { ...this.address, ...res.data });
        });
      }
    },
    search(search) {
      this.currentLocation = null;
      if (search && search.trim() !== "") {
        this.$emit("input", {
          id: null,
          country: null,
          full_address: null,
          region: null,
          province: null,
          postcode: null,
          zone: null,
          city: null,
          street: null,
          number: null,
          latitude: null,
          longitude: null,
        });
        this.$axios(urlLocation + search + "?lang=" + this.currentLanguage).then((res) => {
          this.locations = res.data.predictions.map((el) => {
            let response = {
              value: el.place_id,
            };

            const searchs = search.toLowerCase();
            response.name = el.description.toLowerCase().replace(searchs, "<strong>" + searchs + "</strong>");

            if (el.description.indexOf("<strong>") === 0) {
              response.name =
                "<strong>" + el.description.charAt(8).toUpperCase() + el.description.slice(9, el.description.length);
            } else {
              response.name = el.description.charAt(0).toUpperCase() + el.description.slice(1, el.description.length);
            }
            return response;
          });
        });
      } else {
        this.locations = [];
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.currentLocation = this.value;
        this.formatted_address = val && val.full_address ? val.full_address : null;
      },
    },
  },
};
</script>
