export default {
  back: "back to all projects",
  title: "Projects",
  modify: "Edit a project",
  general_infos: "General Information",
  properties: "Properties",
  no_project: "No project",
  add_phase: "Add Phase",
  create: "Create project",
  project_name: "Project name",
  add_property: "Add property",
  loading_projects: "Loading projects",
  add_address: "Add address",
  overall_data: "Average project infos",
  notifications: {
    updated: "Project updated",
    deleted: "Project deleted",
    created: "Project created",
  },
};
