var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t("overall.currency")))]),_c('Select',{attrs:{"options":[
      { name: 'Euro (€)', value: 'eur' },
      { name: 'USD ($)', value: 'usd' },
      { name: 'GPB (£)', value: 'gpb' },
      { name: 'AUD ($)', value: 'aud' },
      { name: 'CAD ($)', value: 'cad' },
      { name: 'CHF (Fr.)', value: 'chf' },
      { name: 'JPY (¥)', value: 'jpy' } ]},model:{value:(_vm.preferences.currency),callback:function ($$v) {_vm.$set(_vm.preferences, "currency", $$v)},expression:"preferences.currency"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }