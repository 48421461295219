export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.global.user;
    },
  },
  methods: {
    formatCollaboratorsStr(collaborators, emptyString = "") {
      if (!collaborators || !collaborators.length) {
        return emptyString;
      }
      const collaboratorsArr = collaborators.map((item) => {
        return `${item.first_name} ${item.last_name}`;
      });
      return collaboratorsArr.join(", ");
    },
  },
};
