<template>
  <transition name="fade">
    <div id="border-notifications" v-if="isOn">
      <div class="border-notification active" :class="status">
        <button class="remove" @click="isOn = false">
          <svg
            enable-background="new 0 0 212.982 212.982"
            viewBox="0 0 212.982 212.982"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="m131.8 106.49 75.936-75.936c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312 0l-75.937 75.937-75.937-75.938c-6.99-6.99-18.322-6.99-25.312 0-6.989 6.99-6.989 18.323 0 25.312l75.937 75.936-75.937 75.937c-6.989 6.99-6.989 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0l75.937-75.937 75.937 75.937c6.989 6.99 18.322 6.99 25.312 0s6.99-18.322 0-25.312z"
              fill-rule="evenodd"
            ></path>
          </svg>
        </button>
        <span>{{ this.message }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
import notifBus from "@/bus/notification-bus";
export default {
  data() {
    return {
      status: null,
      isOn: false,
      message: "",
      time: 3000,
      timer: null,
    };
  },
  created() {
    notifBus.$on("show", (payload) => {
      this.status = payload.status;
      this.message = payload.message;
      this.isOn = true;
      this.timer = setTimeout(() => {
        this.isOn = false;
      }, this.time);
    });

    notifBus.$on("clear", () => {
      this.status = null;
      this.message = "";
      this.isOn = false;
    });
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.35s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
