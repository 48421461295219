export default {
  edition: "Editar un cliente",
  pref_language: "Idioma",
  add_contact: "Añadir un contacto",
  timeline: "Línea de tiempo",
  modify: "Modificar el cliente",
  back: "Los clientes",
  follow_up: "Seguimiento del cliente",
  historical: "Histórico",
  create: "Crear un cliente",
  is_activated: "Cliente activo",
  client_infos: "Informaciones del cliente",
  properties_suggestion: "Sugerencia de propiedad",
  source: "Fuente",

  add_event: "Añadir un evento",
  add_deal: "Añadir un trato",
  event_type: "Tipo de evento",
  add_follow_up: "Añadir un seguimiento",

  properties_visit: "Visita de propiedades",
  appointment: "Cita",
  note: "Nota",
  other: "Otro",
  title: "Titulo",
  description: "Descripción",
  date: "Fecha",
  add_email: "Añadir un email",
  add_phone: "Añadir un numero",
  popup: {
    add_title: "Añadir un titulo",
    add_description: "Añadir una descripción",
    add_time: "Definir una hora",
  },
  hours: "Horas",
  no_client: "No ay clientes",
  no_event: "No ay eventos",

  notifications: {
    saved: "Cliente guardado",
  },
  lead: "Leads",
  booking: "Reserva",
  buying: "Comprar",
  trading: "Negociar",
  searching: "Buscar",
  specifications: "Especificaciones",
  priority_level: "Nivel de prioridad",
  very_low: "Muy bajo",
  low: "Bajo",
  medium: "Mediano",
  high: "Alto",
  very_high: "Muy alto",

  deal_saved: "Deal guardado",
  event_saved: "Evento guardado",
  is_already_done: "Ya fue executado",
  associated_properties: "Propiedades asociadas",
  state: "Estado",
  expected_date: "Fecha deseada",
  expected_price: "Precio deseado",
  professional: "Profesional",
  private: "Privado",
};
