<template>
  <div class="pagination d-flex justify-content-between">
    <button :class="{ disabled: currentPage < 2 }" class="left" @click="currentPage--">
      <arrowSVG />
    </button>

    <span class="text"
      ><input class="input-current-page" type="number" v-model="currentPage" /> /
      <strong class="number-pages">{{ numberPagination() }}</strong></span
    >
    <button :class="{ disabled: currentPage >= numberPagination() }" @click="currentPage++">
      <arrowSVG />
    </button>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {
      currentPage: 1,
      elementsPerPage: 12,
    };
  },
  props: {
    value: {
      type: Array,
    },
  },
  methods: {
    numberElements() {
      return this.value.length;
    },
    //@ts-ignore
    elementsToBeDisplayed() {
      //@ts-ignore
      const start = this.elementsPerPage * (this.currentPage - 1);
      //@ts-ignore
      const end =
        start + this.elementsPerPage > this.numberElements()
          ? this.numberElements(this.value)
          : start + this.elementsPerPage;
      //@ts-ignore
      this.$emit("changeCurrentPage", this.value.slice(start, end));

      return this.value.slice(start, end);
    },
    //@ts-ignore
    numberPagination() {
      //@ts-ignore
      const addOne = this.numberElements(this.value) / this.elementsPerPage > 0 ? 1 : 0;
      //@ts-ignore
      return Math.floor(this.numberElements(this.value) / this.elementsPerPage) + addOne;
    },
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.currentPage = 1;
        this.elementsToBeDisplayed();
      },
    },
    currentPage: {
      deep: true,
      immediate: true,
      handler() {
        const newQuery = {
          p: this.currentPage,
        };
        if (this.currentPage != this.$route.query.p)
          this.$router.replace({ name: this.$route.name, query: { ...newQuery } });
        if (this.currentPage !== "") this.elementsToBeDisplayed();
      },
    },
  },
};
</script>
