export default {
  // FRENCH
  properties: "Properties",
  create: "Create property",
  modify: "Edit property",
  property: "Property",
  back: "Properties",
  no_property: "No property",
  title: {
    title: "Title",
    placeholder: "Add title",
  },
  is_activated: "Activated",
  description: {
    title: "Property description",
    placeholder: "Add description",
  },
  type: {
    title: "Type",
    villa: "Villa",
    flat: "Apartment",
    house: "House",
    office: "Office",
    land: "land",
    garage: "Garage",
    bungalow: "Bungalow",
    duplex: "Duplex",
    professional_office: "Professional Office",
    building: "Building",
    industrial_land: "Industrial Land",
    agricultural_land: "Agricultural Land",
    penthouse: "Penthouse",
  },
  status: {
    title: "Status",
    sell: "To sell",
    rent: "To rent",
    sold: "Sold",
    booked: "Booked",
    stock_in_trade: "Stock in trade",
  },
  availability: "Availability",

  price: "Price",

  platforms: "Platforms",
  // Box content
  labels: {
    specifications: "Specifications",
    options: "Options",
    platforms: "Platforms",
  },
  construction: {
    title: "Construction",
    new: "New",
    used: "used",
    reworked: "Refursbished",
    built_in: "Built in",
  },
  garden: "Garden",
  indoor_area: "Indoor area",
  land_area: "Land area",
  total_area: "Total area",
  bedrooms: "Bedrooms",
  bathrooms: "Bathrooms",
  golf_distance: "Golf distance",
  airport_distance: "Airport distance",
  dressing: "Dressing",
  jacuzzi: "Jacuzzi",
  sauna: "Sauna",
  alarm: "Alarm",
  fireplace: "Fireplace",
  cellar: "Cellar",
  cellar_area: "Cellar area",
  heating: "Heating",
  television: "Television",
  fuel_oil: "Fuel Oil",
  wood_stove: "Wood Stove",
  pellet_stove: "Pellet Stove",
  electric: "Electric",
  gaz: "Gaz",
  underfloor_heating: "Underfloor heating",
  swimming_pool: {
    title: "Swimming pool",
    private: "Private",
  },
  car_park: {
    title: "Car park",
    garage: "Garage",
  },
  parking: "Car Park",
  furniture: "Furniture",
  disabled_access: "Disabled access",
  beach_distance: "Beach distance",
  sun_orientation: "Sun orientation",
  charges: "Charges",
  land_register: "Land register",
  accommodation: "Accommodation",
  commodities: "Commodities",

  // TO ADD
  terrace: "Terrace",
  patio: "Patio",
  terrace_area: "Terrace area",
  patio_area: "Patio area",
  energy_performance: "Energy performance",
  sea_sight: "Sea sight",
  shops_distance: "Shops distance",
  floor: {
    title: "Floor",
    ground_floor: "Ground floor",
  },
  golf: "Golf",

  // OPTIONS
  air_conditioning: "Air conditioning",
  pre_installed: "Pre-installed",
  first_line: "First line",
  solarium: "Solarium",
  elevator: "Elevator",
  wifi: "Wifi",
  spa: "Spa",
  fitness: "Fitness",
  animals: {
    title: "Animals",
    allowed: "Allowed",
    not_allowed: "Not allowed",
  },

  // RIGHT PANEL
  no_image: "No image",
  manage_images: "Manage images",
  reference: "Reference",

  //TAGS
  tags: "Tags",
  new_price: "New price",
  opportunity: "Opportunity",
  new_property: "New property",
  new_project: "New project",

  library: {
    title: "Manage photos",
    images: "Images",
    videos: "Videos",
    add_picture: "Add picture",
    no_picture: "No image !",
    drag_and_drop: "Drag and drop images.",
  },

  notification: {
    created: "Property created",
    updated: "Property updated",
    deleted: "Property deleted",
  },
  // PROPERTIES
  name: "name",
  location: "location",
  main_specs: "Main specifications",
  second_specs: "Secondary specifications",
  associated_key: "Associated Key",
  key_name: "Key name",
  partner: "Partner",

  confirm_delete: "Are you sure you want to delete ?",
  loading_properties: "Loading properties...",
  warning_property_deactivated: "This property is deactivated",
  no_project: "No related project",
};
