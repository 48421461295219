import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { apolloProvider } from "./graphql";

Vue.config.productionTip = false;

import { mix } from "./mixins/core";
import VueCookies from "vue-cookies";

import { LocalizationPlugin } from "@/plugins/localization";
import { AxiosPlugin } from "@/plugins/axios";

Vue.mixin(mix);
Vue.use(VueCookies);
Vue.use(LocalizationPlugin);
Vue.use(AxiosPlugin);
import Datepicker from "@/components/Datepicker.vue";
import moment from "moment";
import Pagination from "@/components/Pagination.vue";
import Select from "@/components/Select.vue";
import Editor from "@/components/Editor.vue";
import SelectSearch from "@/components/SelectSearch.vue";
import Textarea from "@/components/Textarea.vue";
import Input from "@/components/Input.vue";
import MultiSelect from "@/components/MultiSelect.vue";
import Tag from "@/components/Tag.vue";
import Autocomplete from "@/components/Autocomplete.vue";
import Checkbox from "@/components/Checkbox.vue";
import AddressAutocomplete from "@/components/AddressAutocomplete.vue";
import RangePicker from "@/components/RangePicker.vue";
import ToggleButton from "@/components/ToggleButton.vue";
import crossSVG from "@/assets/img/cross-fat.svg";
import moreSVG from "@/assets/img/more.svg";
import arrowSVG from "@/assets/img/arrow.svg";
import thumbnailSVG from "@/assets/img/thumbnail.svg";
//@ts-ignore
import draggable from "vuedraggable";

//@ts-ignore
Vue.component("Datepicker", Datepicker);
Vue.component("draggable", draggable);
Vue.component("Select", Select);
Vue.component("SelectSearch", SelectSearch);
Vue.component("Textarea", Textarea);
Vue.component("Input", Input);
Vue.component("Pagination", Pagination);
Vue.component("Editor", Editor);
//@ts-ignore
Vue.component("MultiSelect", MultiSelect);
Vue.component("Tag", Tag);
Vue.component("Checkbox", Checkbox);
Vue.component("Autocomplete", Autocomplete);
Vue.component("RangePicker", RangePicker);
Vue.component("AddressAutocomplete", AddressAutocomplete);
Vue.component("ToggleButton", ToggleButton);
Vue.component("crossSVG", crossSVG);
Vue.component("moreSVG", moreSVG);
Vue.component("arrowSVG", arrowSVG);
Vue.component("thumbnailSVG", thumbnailSVG);

Vue.prototype.moment = moment;

const vue = new Vue({
  router,
  store,
  //@ts-ignore
  render: (h) => h(App),
}).$mount("#app");
//@ts-ignore
store.$vm = vue;
