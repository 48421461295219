<template>
  <div>
    <Select v-model="selectedAssignedAgentCloned" :options="relatedCollaborator" />
    <ul class="tag-list _inline" style="margin-top: 10px;">
      <li v-for="c in assignedAgentsCloned" :key="c.token">
        <button @click="removeCollaborator(c.token)">
          <span class="tag _c-blue"
            ><span>{{ c.first_name + " " + c.last_name }}</span
            ><span>x</span></span
          >
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "CollaboratorHandler",
  props: {
    agents: {
      required: false,
    },
    assignedAgents: {
      required: false,
    },
    /*value: {
      required: false,
      default: "",
    },*/
  },
  data() {
    return {
      assignedAgentsCloned: [],
      selectedAssignedAgentCloned: null,
    };
  },
  watch: {
    selectedAssignedAgentCloned(value) {
      if (value !== "add-collaborator" && value) {
        const isAlready = this.assignedAgentsCloned.find((el) => el.token === value);
        if (!isAlready) {
          this.assignedAgentsCloned.push(
            this.agents.find((el) => {
              if (el.token === value) return el;
            }),
          );
        }
        // Revert to placeholder
        const timer = setTimeout(() => {
          this.selectedAssignedAgentCloned = "add-collaborator";
          clearTimeout(timer);
        }, 10);
      }
      this.emitChange();
    },
    // Do this for initial props
    assignedAgents(value, oldValue) {
      if (value && value !== oldValue) {
        this.assignedAgentsCloned = value;
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.global.user;
    },
    relatedCollaborator() {
      let options = [];
      this.agents.forEach((agent, i) => {
        const isAlready = this.assignedAgentsCloned.find((assignedAgent) => assignedAgent.token === agent.token);
        if (!isAlready) {
          options.push({
            name: agent.first_name + " " + agent.last_name,
            value: agent.token,
          });
        }
      });
      options.unshift({
        name: "+ Add Collaborator",
        value: "add-collaborator",
      });
      return options;
    },
  },
  methods: {
    removeCollaborator(value) {
      this.assignedAgentsCloned.splice(
        this.assignedAgentsCloned.findIndex((el) => {
          if (el.token === value) return true;
        }),
        1,
      );
      this.emitChange();
    },
    emitChange() {
      this.$emit("change", { collaborators: this.assignedAgentsCloned });
    },
  },
  created() {
    if (this.assignedAgents) {
      this.assignedAgentsCloned = [...this.assignedAgents];
    }
  },
};
</script>
