<template>
  <div class="settings">
    <div class="d-flex menu">
      <button :class="{ active: tab === 'general' }" @click="tab = 'general'">
        {{ $t("overall.general_information") }}
      </button>
      <button :class="{ active: tab === 'languages' }" @click="tab = 'languages'">{{ $t("overall.languages") }}</button>
      <button :class="{ active: tab === 'social' }" @click="tab = 'social'">{{ $t("overall.social_media") }}</button>
      <button :class="{ active: tab === 'preferences' }" @click="tab = 'preferences'">
        {{ $t("overall.preferences") }}
      </button>
    </div>
    <div class="content">
      <Languages :lang="lang" :options="langOptions" @input="updateArray" v-show="tab === 'languages'" />
      <GeneralInformation :infos="overall" v-show="tab === 'general'" />
      <SocialMedia :social="social" v-show="tab === 'social'" />
      <Preferences :preferences="overall" v-show="tab === 'preferences'" />
    </div>
    <footer class="d-flex justify-content-end">
      <div class="cta" @click="save">{{ $t("overall.save") }}</div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import Languages from "@/pages/settings/Languages";
import GeneralInformation from "@/pages/settings/GeneralInformation";
import SocialMedia from "@/pages/settings/SocialMedia";
import Preferences from "@/pages/settings/Preferences";
import notifBus from "@/bus/notification-bus";
import modalBus from "@/bus/modal-bus";
import { mapState } from "vuex";

export default Vue.extend({
  name: "Settings",
  components: {
    Languages,
    GeneralInformation,
    SocialMedia,
    Preferences,
  },
  data() {
    return {
      modalBus,
      tab: "general",
      lang: [],
      langOptions: [
        { value: "en", name: "english" },
        { value: "fr", name: "french" },
        { value: "de", name: "german" },
        { value: "es", name: "spanish" },
        { value: "it", name: "italian" },
        { value: "nl", name: "dutch" },
        { value: "pt", name: "portuguese" },
        { value: "ru", name: "russian" },
        { value: "pl", name: "polish" },
        { value: "no", name: "norwegian" },
        { value: "sw", name: "swedish" },
        { value: "bg", name: "bulgarian" },
        { value: "da", name: "danish" },
        { value: "cs", name: "czech" },
        { value: "fi", name: "finnish" },
        { value: "el", name: "greek" },
      ],
      overall: {
        company_name: null,
        website: null,
        email: null,
        phone: null,
        address: null,
        logo: null,
        currency: "eur",
      },
      social: {
        facebook: null,
        instagram: null,
        twitter: null,
        linkedin: null,
        youtube: null,
      },
    };
  },
  methods: {
    updateArray(event) {
      this.$set(this, "lang", event);
    },
    save() {
      this.$axios
        .post("/api/settings", {
          lang: this.lang,
          overall: this.overall,
          social: this.social,
        })
        .then((res) => {
          console.log(this.lang);
          console.log(
            this._global.availableLanguages.filter((el) => {
              console.log(el);
              console.log("-----");
              this.lang.includes(el.value);
            }),
          );

          let newLanguages = [];
          this.lang.forEach((el, i) => {
            newLanguages.push({
              //@ts-ignore
              name: this.$t("overall.lang." + el),
              value: el,
              order: i,
            });
          });

          this.$store.commit("setLanguages", newLanguages);

          notifBus.$emit("show", {
            status: "success",
            message: this.$t("settings.notification.updated"),
          });
          modalBus.$emit("toggle", false);
        })
        .catch((res) => {
          this.genericError();
        });
    },
  },
  created() {
    this.$axios
      .get("/api/settings")
      .then((res) => {
        this.overall = res.data.overall;
        this.social = res.data.social;
        this.lang = this.$store.state.global.availableLanguages.map((el) => el.value);
      })
      .catch((res) => {
        this.genericError();
      });
  },
  computed: {
    ...mapState(["settings"]),
  },
});
</script>
