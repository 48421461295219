export default {
  global: {
    firstLoadedRoute: { name: "", path: "" },
    rServer: process.env.VUE_APP_UNIQ_API_URL,
    googleMapApiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    isMenu: true,
    availableLanguages: [],
    interfaceLanguages: ["fr", "en", "es", "pt", "de"],
    countries: [
      "france",
      "belgium",
      "spain",
      "portugal",
      "germany",
      "luxembourg",
      "netherlands",
      "russia",
      "sweden",
      "norway",
    ],
    user: {
      first_name: "",
      last_name: "",
      capabilities: [],
      role: { name: "" },
    },
  },
  settings: {
    social: {
      facebook: null,
      twitter: null,
      linkedin: null,
      instagram: null,
      youtube: null,
    },
    currency: "eur",
    company_name: null,
    website: null,
    email: null,
    phone: null,
    address: {
      city: null,
      street: null,
      number: null,
      postcode: null,
      country: null,
    },
  },
};
