import Vue from "vue";
import {
  agendaRoutesCapabilities,
  blogRoutesCapabilities,
  buildersRoutesCapabilities,
  clientsRoutesCapabilities,
  jobsRoutesCapabilities,
  newsRoutesCapabilities,
  projectsRoutesCapabilities,
  propertiesRoutesCapabilities,
  settingsRoutesCapabilities,
  usersRoutesCapabilities,
} from "@/types";

// @ts-ignore
function lazyLoad(view) {
  return () => import("@/pages/" + view + ".vue");
}

export const noGuardRoutes = ["login"];

export const routes = [
  {
    path: "/",
    name: "entrypoint",
    beforeEnter(to: any, from: any, next: any) {
      Vue.$cookies.get("jkt") ? next({ name: "properties" }) : next({ name: "login" });
    },
  },
  {
    path: "/login",
    name: "login",
    component: lazyLoad("login/Login"),
    beforeEnter(to: any, from: any, next: any) {
      Vue.$cookies.get("jkt") ? next({ name: "properties" }) : next();
    },
  },
  { path: "/dashboard", name: "dashboard", component: lazyLoad("dashboard/Index") },
  {
    path: "/calendar",
    name: "calendar",
    component: lazyLoad("calendar/Index"),
    meta: {
      capabilities: agendaRoutesCapabilities,
    },
  },
  {
    path: "/properties",
    component: lazyLoad("properties/Index"),
    children: [
      {
        path: "",
        name: "properties",
        component: lazyLoad("properties/List"),
        meta: {
          capabilities: propertiesRoutesCapabilities,
        },
      },
      {
        path: "create",
        name: "create-property",
        component: lazyLoad("properties/Edit"),
        meta: {
          capabilities: propertiesRoutesCapabilities,
        },
      },
      {
        path: "edit/:token",
        name: "edit-property",
        component: lazyLoad("properties/Edit"),
        meta: {
          capabilities: propertiesRoutesCapabilities,
        },
      },
    ],
    meta: {
      capabilities: propertiesRoutesCapabilities,
    },
  },
  {
    path: "/blog",
    component: lazyLoad("properties/Index"),
    children: [
      {
        path: "",
        name: "blog",
        component: lazyLoad("blog/List"),
        meta: {
          capabilities: blogRoutesCapabilities,
        },
      },
      {
        path: "create",
        name: "create-article",
        component: lazyLoad("blog/Edit"),
        meta: {
          capabilities: blogRoutesCapabilities,
        },
      },
      {
        path: "edit/:token",
        name: "edit-article",
        component: lazyLoad("blog/Edit"),
        meta: {
          capabilities: blogRoutesCapabilities,
        },
      },
    ],
    meta: {
      capabilities: blogRoutesCapabilities,
    },
  },
  {
    path: "/news",
    component: lazyLoad("properties/Index"),
    children: [
      {
        path: "",
        name: "news",
        component: lazyLoad("news/List"),
        meta: {
          capabilities: newsRoutesCapabilities,
        },
      },
      {
        path: "create",
        name: "create-news",
        component: lazyLoad("news/Edit"),
        meta: {
          capabilities: newsRoutesCapabilities,
        },
      },
      {
        path: "edit/:token",
        name: "edit-news",
        component: lazyLoad("news/Edit"),
        meta: {
          capabilities: newsRoutesCapabilities,
        },
      },
    ],
    meta: {
      capabilities: newsRoutesCapabilities,
    },
  },
  /// AAA
  {
    path: "/jobs",
    component: lazyLoad("properties/Index"),
    children: [
      {
        path: "",
        name: "jobs",
        component: lazyLoad("jobs/List"),
        meta: {
          capabilities: jobsRoutesCapabilities,
        },
      },
      {
        path: "create",
        name: "create-jobs",
        component: lazyLoad("jobs/Edit"),
        meta: {
          capabilities: jobsRoutesCapabilities,
        },
      },
      {
        path: "edit/:token",
        name: "edit-jobs",
        component: lazyLoad("jobs/Edit"),
        meta: {
          capabilities: jobsRoutesCapabilities,
        },
      },
    ],
    meta: {
      capabilities: jobsRoutesCapabilities,
    },
  },
  /// AAA
  {
    path: "/projects",
    component: lazyLoad("properties/Index"),
    children: [
      {
        path: "",
        name: "projects",
        component: lazyLoad("projects/List"),
        meta: {
          capabilities: projectsRoutesCapabilities,
        },
      },
      {
        path: "create",
        name: "create-project",
        component: lazyLoad("projects/Edit"),
        meta: {
          capabilities: projectsRoutesCapabilities,
        },
      },
      {
        path: "edit/:token",
        name: "edit-project",
        component: lazyLoad("projects/Edit"),
        meta: {
          capabilities: projectsRoutesCapabilities,
        },
      },
    ],
    meta: {
      capabilities: projectsRoutesCapabilities,
    },
  },
  {
    path: "/builders",
    component: lazyLoad("builders/Index"),
    children: [
      {
        path: "",
        name: "builders",
        component: lazyLoad("builders/List"),
        meta: {
          capabilities: buildersRoutesCapabilities,
        },
      },
      {
        path: "create",
        name: "create-builder",
        component: lazyLoad("builders/Edit"),
        meta: {
          capabilities: buildersRoutesCapabilities,
        },
      },
      {
        path: "edit/:token",
        name: "edit-builder",
        component: lazyLoad("builders/Edit"),
        meta: {
          capabilities: buildersRoutesCapabilities,
        },
      },
    ],
    meta: {
      capabilities: buildersRoutesCapabilities,
    },
  },
  {
    path: "/customers",
    component: lazyLoad("customers/Index"),
    children: [
      {
        path: "follow-ups",
        component: lazyLoad("customers/followUps/Index"),
        children: [
          {
            path: "",
            name: "follow-ups",
            component: lazyLoad("customers/followUps/List"),
            meta: {
              capabilities: clientsRoutesCapabilities,
            },
          },
        ],
      },
      {
        path: "clients",
        component: lazyLoad("customers/clients/Index"),
        children: [
          {
            path: "",
            name: "clients",
            component: lazyLoad("customers/clients/List"),
            meta: {
              capabilities: clientsRoutesCapabilities,
            },
          },
          {
            path: "create",
            name: "create-client",
            component: lazyLoad("customers/clients/Edit"),
            meta: {
              capabilities: clientsRoutesCapabilities,
            },
          },
          {
            path: "edit/:token",
            name: "edit-client",
            component: lazyLoad("customers/clients/Edit"),
            meta: {
              capabilities: clientsRoutesCapabilities,
            },
          },
        ],
      },
    ],
    meta: {
      capabilities: clientsRoutesCapabilities,
    },
  },
  {
    path: "/users",
    component: lazyLoad("users/Index"),
    children: [
      {
        path: "",
        name: "users",
        component: lazyLoad("users/List"),
        meta: {
          capabilities: usersRoutesCapabilities,
        },
      },
      {
        path: "create",
        name: "create-user",
        component: lazyLoad("users/Edit"),
        meta: {
          capabilities: usersRoutesCapabilities,
        },
      },
      {
        path: "edit/:token",
        name: "edit-user",
        component: lazyLoad("users/Edit"),
        meta: {
          capabilities: usersRoutesCapabilities,
        },
      },
    ],
    meta: {
      capabilities: usersRoutesCapabilities,
    },
  },
  {
    path: "/settings",
    component: lazyLoad("settings/Index"),
    name: "settings",
    meta: {
      capabilities: settingsRoutesCapabilities,
    },
  },
  {
    path: "/profile",
    component: lazyLoad("settings/Profile"),
    name: "profile",
    meta: {
      capabilities: settingsRoutesCapabilities,
    },
  },
  { path: "/unauthorized", component: lazyLoad("errors/unauthorized"), name: "unauthorized" },
  { path: "/*", component: lazyLoad("errors/E404"), name: "404" },
];
