import overall from "./overall";
import properties from "./properties";
import clients from "./clients";
import projects from "./projects";
import builders from "./builders";

export default {
  overall,
  properties,
  clients,
  projects,
  builders,
};
