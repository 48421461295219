import Vue from "vue";

const ADD_OPTIONS = "ADD_OPTIONS";

import {AxiosPlugin} from "@/plugins/axios";

Vue.use(AxiosPlugin);
const v = new Vue();

// TODO: continue temporary followUp

let fetched = false;

// initial state
const state = {
  financialTypes: [],
  buyingReasons: [],
  propertiesSpecifications: [],
  propertiesTypes: [],
};

// getters
const getters = {
  //@ts-ignore
  financialTypes: (state) => state.financialTypes,
  //@ts-ignore
  buyingReasons: (state) => state.buyingReasons,
  //@ts-ignore
  propertiesSpecifications: (state) => state.propertiesSpecifications,
  //@ts-ignore
  propertiesTypes: (state) => state.propertiesTypes,
};

// actions
const actions = {
  async fetchOptions({commit, state}: any): Promise<any> {
    if (fetched) {
      return;
    }
    //@ts-ignore
    const response = await v.$axios.get("/api/clients-search-criterias-options");
    if (response.status === 200) {
      let options = response.data;
      fetched = true;
      commit(ADD_OPTIONS, options);
    } else if (response.status === 429) {
      console.log("Error 429", response);
    } else {
      console.log("Fetch followUps error", response.status);
    }
  },
};

const mutations = {
  //@ts-ignore
  [ADD_OPTIONS](state, { financial_types, buying_reasons, properties_specifications, properties_types }) {
    state.financialTypes = financial_types;
    state.buyingReasons = buying_reasons;
    state.propertiesSpecifications = properties_specifications;
    state.propertiesTypes = properties_types;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
