<template>
  <div class="deal-infos">
    <div class="priority-deal">
      <div class="section">
        <label>{{ $t("overall.title") }}</label>
        <input type="text" name="title" v-model="deal.title" :placeholder="$t('overall.title')" />
      </div>
      <div class="flex section two">
        <div class="box">
          <label>{{ $t("clients.priority_level") }}</label>
          <Select
            v-model="deal.priority"
            :options="[
              { name: $t('clients.very_low'), value: 'very_low' },
              { name: $t('clients.low'), value: 'low' },
              { name: $t('clients.medium'), value: 'medium' },
              { name: $t('clients.high'), value: 'high' },
              { name: $t('clients.very_high'), value: 'very_high' },
            ]"
          />
        </div>
        <div class="box">
          <label>{{ $t("clients.state") }}</label>
          <Select
            v-model="deal.state"
            :options="[
              { name: $t('clients.lead'), value: 'lead' },
              { name: $t('clients.searching'), value: 'search' },
              { name: $t('clients.trading'), value: 'trade' },
              { name: $t('clients.booking'), value: 'book' },
              { name: $t('clients.buying'), value: 'buy' },
            ]"
          />
        </div>
      </div>
    </div>

    <h2>{{ $t("clients.specifications") }}</h2>

    <div class="flex section">
      <div class="box">
        <label>{{ $t("clients.expected_date") }}</label>
        <Datepicker v-model="deal.expected_date" />
      </div>
      <div class="box">
        <label>{{ $t("clients.expected_price") }}</label>
        <input type="number" v-model="deal.expected_price" />
      </div>
    </div>
    <div class="section flex">
      <div class="box">
        <label>{{ $t("properties.status.title") }}</label>
        <Select
          v-model="deal.status"
          :options="[
            { name: $t('properties.status.sell'), value: 'sell' },
            { name: $t('properties.status.rent'), value: 'rent' },
            { name: $t('properties.status.sold'), value: 'sold' },
            { name: $t('properties.status.booked'), value: 'booked' },
            { name: $t('properties.status.stock_in_trade'), value: 'stock_in_trade' },
          ]"
        />
      </div>
      <div class="box">
        <label>{{ $t("properties.construction.title") }}</label>
        <Select
          v-model="deal.construction"
          :options="[
            { name: $t('overall.not-specified'), value: null },
            { name: 'New', value: 'new' },
            { name: 'Used', value: 'used' },
            { name: 'Refurbished', value: 'refurbished' },
          ]"
        />
      </div>

      <div class="box">
        <label>{{ $t("properties.type.title") }}</label>
        <Select
          v-model="deal.type"
          :options="[
            { name: $t('properties.type.flat'), value: 'flat' },
            { name: $t('properties.type.villa'), value: 'villa' },
            { name: $t('properties.type.office'), value: 'office' },
            { name: $t('properties.type.house'), value: 'house' },
            { name: $t('properties.twype.land'), value: 'field' },
            { name: $t('properties.type.garage'), value: 'garage' },
            { name: $t('properties.type.bungalow'), value: 'bungalow' },
            { name: $t('properties.type.duplex'), value: 'duplex' },
            { name: $t('properties.type.professional_office'), value: 'professional office' },
            { name: $t('properties.type.building'), value: 'building' },
            { name: $t('properties.type.industrial_land'), value: 'industrial field' },
            { name: $t('properties.type.agricultural_land'), value: 'agricultural field' },
            { name: $t('properties.type.penthouse'), value: 'penthouse' },
          ]"
        />
      </div>
    </div>
    <div class="section flex">
      <div class="box">
        <label>{{ $t("properties.bedrooms") }}</label>
        <Select
          v-model="deal.bedrooms"
          :options="[
            { name: $t('overall.not-specified'), value: null },
            { name: '1', value: 1 },
            { name: '2', value: 2 },
            { name: '3', value: 3 },
            { name: '4', value: 4 },
            { name: '5', value: 5 },
            { name: '6', value: 6 },
            { name: '7', value: 7 },
            { name: '8', value: 8 },
          ]"
        />
      </div>
      <div class="box">
        <label>{{ $t("properties.bathrooms") }}</label>
        <Select
          v-model="deal.bathrooms"
          :options="[
            { name: $t('overall.not-specified'), value: null },
            { name: '1', value: 1 },
            { name: '2', value: 2 },
            { name: '3', value: 3 },
            { name: '4', value: 4 },
            { name: '5', value: 5 },
            { name: '6', value: 6 },
            { name: '7', value: 7 },
            { name: '8', value: 8 },
          ]"
        />
      </div>
    </div>
    <textarea class="editor" v-model="deal.note"></textarea>
    <div class="flex align-content-between">
      <div class="flex">
        <button @click="saveDeal" class="save cta">{{ $t("overall.save") }}</button>
        <button @click="cancelDeal" class="cancel">{{ $t("overall.cancel") }}</button>
      </div>
      <button v-if="this.deal.id" @click="deleteDeal" class="delete cta">{{ $t("overall.delete") }}</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import notifBus from "@/bus/notification-bus";
import modalBus from "@/bus/modal-bus";

export default Vue.extend({
  name: "AddDeal",
  components: {},
  props: ["data"],
  data() {
    return {
      deal: {
        id: null,
        token: null,
        title: null,
        reference: null,
        client: null,
        agent: null,
        workspace: null,
        priority: "medium",
        state: "lead",
        type: null,
        status: null,
        construction: null,
        bedrooms: null,
        bathrooms: null,
        is_pipeline: 0,
        expected_price: null,
        expected_date: null,
        is_success: 0,
        note: null,
      },
    };
  },
  methods: {
    saveDeal() {
      modalBus.sendAndClose();
      this.$axios
        .post("/api/deals", {
          client: this.data.client,
          deal: this.deal,
        })
        .then((res) => {
          modalBus.$emit("add-deal", res.data);
          notifBus.$emit("show", {
            status: "success",
            message: this.$t("clients.deal_saved"),
          });
        });
    },
    cancelDeal() {
      this.deal = { ...this.previous };
      modalBus.$emit("toggle");
    },
    deleteDeal() {
      modalBus.$emit("delete-deal", this.deal.token);
      this.$axios.delete("/api/deals/" + this.deal.token).then((res) => {
        notifBus.$emit("show", {
          status: "success",
          message: this.$t("clients.deal_deleted"),
        });
      });
      modalBus.$emit("toggle");
    },
  },
  created() {
    if (this.data.deal.id !== undefined) {
      this.deal = this.data.deal;
    }
    this.previous = { ...this.deal };
  },
});
</script>
