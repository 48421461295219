import _Vue from "vue";

import axios from "axios";
import Vue from "vue/types/vue";
import state from "@/store/state";
import notifBus from "@/bus/notification-bus";

console.log("API", state.global.rServer);

export function AxiosPlugin(Vue: typeof _Vue, options?: AxiosPluginOptions): void {
  let instance = axios.create({
    baseURL: state.global.rServer,
  });

  if (_Vue.$cookies.get("jkt")) {
    instance.defaults.headers.common["Authorization"] = Vue.$cookies.get("jkt");
  }

  instance.interceptors.request.use(
    function(config) {
      return config;
    },
    function(error) {
      notifBus.$emit("show", {
        type: "error",
        message: "An error occured",
      });
      return Promise.reject(error);
    },
  );

  Vue.prototype.$axios = instance;
}

export class AxiosPluginOptions {
  // add stuff
}
