























































import userSVG from "@/assets/img/user.svg";
import logoutSVG from "@/assets/img/logout.svg";
import modalBus from "@/bus/modal-bus";
import menuSVG from "../assets/img/menu.svg";
import arrowSVG from "@/assets/img/arrow.svg";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import Vue from "vue";

export default Vue.extend({
  name: "Menu",
  props: ["details"],
  methods: {
    ...mapActions({
      _logout: "logout",
    }),
    deleteMethod() {},
    duplicateMethod() {},
    modalSettings() {
      modalBus.$emit("change", {
        component: "Settings",
      });
      modalBus.$emit("set-size", {
        width: "700px",
        height: "auto",
        "margin-top": "50px",
      });

      modalBus.$emit("toggle");
    },
    logout() {
      this.$cookies.remove("jkt");
      this.$router.push({ name: "login" });
      this._logout();
    },
  },
  watch: {
    $cookies: {
      handler() {
        this.isOpen = this.$cookies.get("jkt") !== undefined;
      },
      deep: true,
    },
  },
  data() {
    return {
      dropdownActions: false,
      isOpen: this.$cookies.get("jkt") !== undefined,
      showFooter: false,
    };
  },
  created() {
    //this.$store.commit("TOGGLE_MENU",true);
  },
  components: {
    userSVG,
    logoutSVG,
    arrowSVG,
    menuSVG,
  },
});
