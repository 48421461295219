import { NavigationGuardNext, Route } from "vue-router";
import Vue from "vue";
import store from "@/store";
import userCan from "@/helpers/userCan";
import { Capability } from "@/types";

const routeNotAuthorized = { name: "unauthorized" };

/**
 * Let user access the route or redirect to routeNotAuthorized
 * @param next
 * @param userCapabilities
 * @param routeCapabilities
 */
const nextOrRedirect = (
  next: NavigationGuardNext<Vue>,
  userCapabilities: Array<Capability>,
  routeCapabilities: Array<Capability>,
) => {
  if (!userCan(userCapabilities, routeCapabilities)) {
    next(routeNotAuthorized);
  } else {
    next();
  }
}

export default (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const routeCapabilities = to.meta.capabilities;
  if (!routeCapabilities || !routeCapabilities.length) {
    // The routes have not required capabilities, go next
    next();
  } else {
    if ( store.state.global.user && store.state.global.user.capabilities ) {
      // @ts-ignore
      const userCapabilities: Array<Capability> = store.state.global.user.capabilities;
      if (userCapabilities && userCapabilities.length) {
        nextOrRedirect(next, userCapabilities, routeCapabilities)
      } else {
        // The user has not yet been fetched
        console.log("In redirectIfUserDontHaveCapabilities - user profile not yet fetched, no redirection for now.")
      }
    }
  }
};
