import Modal from "@/components/Modal.vue";
import Library from "@/components/Library.vue";
import AddDeal from "@/pages/customers/clients/AddDeal.vue";
import AddEvent from "@/pages/customers/clients/AddEvent.vue";
import AddProject from "@/pages/projects/AddProject.vue";
import Prompt from "@/pages/_core/Prompt.vue";
import AddClient from "@/pages/customers/clients/AddClient.vue";
import AddSearchCriteria from "@/pages/customers/clients/AddSearchCriteria.vue";
import Settings from "@/pages/settings/Index.vue";
import modalBus from "@/bus/modal-bus";

export default {
  components: {
    Modal,
    Library,
    AddDeal,
    AddEvent,
    AddClient,
    AddProject,
    Settings,
    Prompt,
    AddSearchCriteria,
  },
  data() {
    return {
      notify: null,
      modal: {
        component: "",
        data: {},
      },
    };
  },
  created() {
    modalBus.$on("change", (content: { component: string; data: object }) => {
      // @ts-ignore
      this.modal.data = content.data;
      // @ts-ignore
      this.modal.component = content.component;
    });
    modalBus.$on("clear", () => {
      // @ts-ignore
      this.modal.data = {};
      // @ts-ignore
      this.modal.component = "";
    });
  },
};
