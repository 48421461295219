<template>
  <div class="select" v-click-outside:isOpen :class="{ forward: isOpen }">
    <div class="select__front" @click="toggleOptions">
      <span class="selected" v-if="currentValue">{{ currentValue.name }}</span>
      <span class="selected" v-if="!currentValue">{{ placeholder }}</span>
    </div>
    <ul class="options" v-show="isOpen">
      <li
        :class="currentValue === o ? 'is-selected' : ''"
        v-for="(o, i) in optionsCloned"
        :key="o + i"
        @click="changeValue(o)"
      >
        {{ o.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    nullable: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select",
    },
    multi: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultIsEmptyValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: [String, Number],
    },
  },
  watch: {
    value(newValue) {
      let matchingObject = this.optionsCloned.find((el) => {
        return el.value === newValue;
      });

      if (matchingObject !== undefined) {
        this.changeValue(matchingObject, false);
      } else {
        /* console.warn("Value '" + newValue + "' does not match any option in ");
          console.warn(this.$el);*/
      }
    },
    options() {
      const options = JSON.parse(JSON.stringify(this.options));
      if (this.defaultIsEmptyValue) {
        options.unshift(this.getEmptyValue());
      }
      this.optionsCloned = options;
      if (!this.defaultIsEmptyValue) {
        if (!this.selected && this.optionsCloned.length > 0) {
          (this.selectDefault && this.optionsCloned === undefined) ||
          this.optionsCloned ||
          (null && this.optionsCloned.length <= 0)
            ? this.changeValue(this.optionsCloned[0])
            : this.$emit("input", null);
        }
      }
    },
    optionsCloned() {
      this.selected = false;
      for (let o of this.optionsCloned) {
        if (this.value === o.value) {
          this.selected = true;
          this.changeValue(o);
          break;
        } else if (o.hasOwnProperty("selected") && o.selected) {
          this.changeValue(o);
          this.selected = true;
        }
      }
    },
  },
  data() {
    return {
      currentValue: {
        name: "",
        value: "",
      },
      lock: 0,
      isOpen: false,
      optionsCloned: [],
      selected: false,
    };
  },
  methods: {
    toggleOptions() {
      this.isOpen = !this.isOpen;
    },
    changeValue(val = null, $emit = true) {
      this.currentValue = val;
      this.isOpen = false;
      if ($emit) {
        this.$emit("input", this.currentValue.value);
      }
    },
    getEmptyValue() {
      return {
        value: null,
        name: this.placeholder,
      };
    },
  },
  mounted() {
    this.optionsCloned = JSON.parse(JSON.stringify(this.options));
    for (let option of this.optionsCloned) {
      if (this.value === option.value) {
        this.selected = true;
        this.changeValue(option);
        break;
      } else if (option.hasOwnProperty("selected") && option.selected) {
        this.changeValue(option);
        this.selected = true;
      }
    }
    if (this.defaultIsEmptyValue) {
      this.optionsCloned.unshift(this.getEmptyValue());
    } else {
      if (!this.selected && this.optionsCloned.length > 0) {
        (this.selectDefault && this.optionsCloned === undefined) ||
        this.optionsCloned ||
        (null && this.optionsCloned.length <= 0)
          ? this.changeValue(this.optionsCloned[0])
          : this.$emit("input", null);
      }
    }
  },
};
</script>

<style scoped>
.forward {
  z-index: 9999;
}
</style>
