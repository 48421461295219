var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deal-infos"},[_c('div',{staticClass:"priority-deal"},[_c('div',{staticClass:"section"},[_c('label',[_vm._v(_vm._s(_vm.$t("overall.title")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deal.title),expression:"deal.title"}],attrs:{"type":"text","name":"title","placeholder":_vm.$t('overall.title')},domProps:{"value":(_vm.deal.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.deal, "title", $event.target.value)}}})]),_c('div',{staticClass:"flex section two"},[_c('div',{staticClass:"box"},[_c('label',[_vm._v(_vm._s(_vm.$t("clients.priority_level")))]),_c('Select',{attrs:{"options":[
            { name: _vm.$t('clients.very_low'), value: 'very_low' },
            { name: _vm.$t('clients.low'), value: 'low' },
            { name: _vm.$t('clients.medium'), value: 'medium' },
            { name: _vm.$t('clients.high'), value: 'high' },
            { name: _vm.$t('clients.very_high'), value: 'very_high' } ]},model:{value:(_vm.deal.priority),callback:function ($$v) {_vm.$set(_vm.deal, "priority", $$v)},expression:"deal.priority"}})],1),_c('div',{staticClass:"box"},[_c('label',[_vm._v(_vm._s(_vm.$t("clients.state")))]),_c('Select',{attrs:{"options":[
            { name: _vm.$t('clients.lead'), value: 'lead' },
            { name: _vm.$t('clients.searching'), value: 'search' },
            { name: _vm.$t('clients.trading'), value: 'trade' },
            { name: _vm.$t('clients.booking'), value: 'book' },
            { name: _vm.$t('clients.buying'), value: 'buy' } ]},model:{value:(_vm.deal.state),callback:function ($$v) {_vm.$set(_vm.deal, "state", $$v)},expression:"deal.state"}})],1)])]),_c('h2',[_vm._v(_vm._s(_vm.$t("clients.specifications")))]),_c('div',{staticClass:"flex section"},[_c('div',{staticClass:"box"},[_c('label',[_vm._v(_vm._s(_vm.$t("clients.expected_date")))]),_c('Datepicker',{model:{value:(_vm.deal.expected_date),callback:function ($$v) {_vm.$set(_vm.deal, "expected_date", $$v)},expression:"deal.expected_date"}})],1),_c('div',{staticClass:"box"},[_c('label',[_vm._v(_vm._s(_vm.$t("clients.expected_price")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deal.expected_price),expression:"deal.expected_price"}],attrs:{"type":"number"},domProps:{"value":(_vm.deal.expected_price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.deal, "expected_price", $event.target.value)}}})])]),_c('div',{staticClass:"section flex"},[_c('div',{staticClass:"box"},[_c('label',[_vm._v(_vm._s(_vm.$t("properties.status.title")))]),_c('Select',{attrs:{"options":[
          { name: _vm.$t('properties.status.sell'), value: 'sell' },
          { name: _vm.$t('properties.status.rent'), value: 'rent' },
          { name: _vm.$t('properties.status.sold'), value: 'sold' },
          { name: _vm.$t('properties.status.booked'), value: 'booked' },
          { name: _vm.$t('properties.status.stock_in_trade'), value: 'stock_in_trade' } ]},model:{value:(_vm.deal.status),callback:function ($$v) {_vm.$set(_vm.deal, "status", $$v)},expression:"deal.status"}})],1),_c('div',{staticClass:"box"},[_c('label',[_vm._v(_vm._s(_vm.$t("properties.construction.title")))]),_c('Select',{attrs:{"options":[
          { name: _vm.$t('overall.not-specified'), value: null },
          { name: 'New', value: 'new' },
          { name: 'Used', value: 'used' },
          { name: 'Refurbished', value: 'refurbished' } ]},model:{value:(_vm.deal.construction),callback:function ($$v) {_vm.$set(_vm.deal, "construction", $$v)},expression:"deal.construction"}})],1),_c('div',{staticClass:"box"},[_c('label',[_vm._v(_vm._s(_vm.$t("properties.type.title")))]),_c('Select',{attrs:{"options":[
          { name: _vm.$t('properties.type.flat'), value: 'flat' },
          { name: _vm.$t('properties.type.villa'), value: 'villa' },
          { name: _vm.$t('properties.type.office'), value: 'office' },
          { name: _vm.$t('properties.type.house'), value: 'house' },
          { name: _vm.$t('properties.twype.land'), value: 'field' },
          { name: _vm.$t('properties.type.garage'), value: 'garage' },
          { name: _vm.$t('properties.type.bungalow'), value: 'bungalow' },
          { name: _vm.$t('properties.type.duplex'), value: 'duplex' },
          { name: _vm.$t('properties.type.professional_office'), value: 'professional office' },
          { name: _vm.$t('properties.type.building'), value: 'building' },
          { name: _vm.$t('properties.type.industrial_land'), value: 'industrial field' },
          { name: _vm.$t('properties.type.agricultural_land'), value: 'agricultural field' },
          { name: _vm.$t('properties.type.penthouse'), value: 'penthouse' } ]},model:{value:(_vm.deal.type),callback:function ($$v) {_vm.$set(_vm.deal, "type", $$v)},expression:"deal.type"}})],1)]),_c('div',{staticClass:"section flex"},[_c('div',{staticClass:"box"},[_c('label',[_vm._v(_vm._s(_vm.$t("properties.bedrooms")))]),_c('Select',{attrs:{"options":[
          { name: _vm.$t('overall.not-specified'), value: null },
          { name: '1', value: 1 },
          { name: '2', value: 2 },
          { name: '3', value: 3 },
          { name: '4', value: 4 },
          { name: '5', value: 5 },
          { name: '6', value: 6 },
          { name: '7', value: 7 },
          { name: '8', value: 8 } ]},model:{value:(_vm.deal.bedrooms),callback:function ($$v) {_vm.$set(_vm.deal, "bedrooms", $$v)},expression:"deal.bedrooms"}})],1),_c('div',{staticClass:"box"},[_c('label',[_vm._v(_vm._s(_vm.$t("properties.bathrooms")))]),_c('Select',{attrs:{"options":[
          { name: _vm.$t('overall.not-specified'), value: null },
          { name: '1', value: 1 },
          { name: '2', value: 2 },
          { name: '3', value: 3 },
          { name: '4', value: 4 },
          { name: '5', value: 5 },
          { name: '6', value: 6 },
          { name: '7', value: 7 },
          { name: '8', value: 8 } ]},model:{value:(_vm.deal.bathrooms),callback:function ($$v) {_vm.$set(_vm.deal, "bathrooms", $$v)},expression:"deal.bathrooms"}})],1)]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.deal.note),expression:"deal.note"}],staticClass:"editor",domProps:{"value":(_vm.deal.note)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.deal, "note", $event.target.value)}}}),_c('div',{staticClass:"flex align-content-between"},[_c('div',{staticClass:"flex"},[_c('button',{staticClass:"save cta",on:{"click":_vm.saveDeal}},[_vm._v(_vm._s(_vm.$t("overall.save")))]),_c('button',{staticClass:"cancel",on:{"click":_vm.cancelDeal}},[_vm._v(_vm._s(_vm.$t("overall.cancel")))])]),(this.deal.id)?_c('button',{staticClass:"delete cta",on:{"click":_vm.deleteDeal}},[_vm._v(_vm._s(_vm.$t("overall.delete")))]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }