export default {
  create: "Create new builder",
  title: "Builders",
  loading_builders: "Loading Builders",
  projects_list: "Projects list",
  notifications: {
    updated: "Builder updated",
    created: "Builder created",
  },
  add_email: "Add email",
  enter_email: "Enter email",
  add_phone: "Add phone",
  enter_phone: "Enter phone",
  no_builder: "No builder",
  no_project: "No project",
};
