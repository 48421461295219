<template>
  <div>
    <h2>{{ $t("overall.currency") }}</h2>
    <Select
      v-model="preferences.currency"
      :options="[
        { name: 'Euro (€)', value: 'eur' },
        { name: 'USD ($)', value: 'usd' },
        { name: 'GPB (£)', value: 'gpb' },
        { name: 'AUD ($)', value: 'aud' },
        { name: 'CAD ($)', value: 'cad' },
        { name: 'CHF (Fr.)', value: 'chf' },
        { name: 'JPY (¥)', value: 'jpy' },
      ]"
    />
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Preferences",
  props: ["preferences"],
});
</script>

<style></style>
