<template>
  <div class="general-information">
    <div class="d-flex">
      <div class="logo hide">
        <h2>{{ $t("overall.logo") }}</h2>
        <label for="logo-file" class="logo-box">
          <span id="logo-file" class="no-picture">{{ $t("parameters.add-picture") }}</span>
        </label>
        <input type="file" class="hide" />
      </div>
      <div class="infos">
        <h2>{{ $t("overall.information") }}</h2>
        <div class="input-box">
          <input
            name="company_name"
            v-model="infos.company_name"
            type="text"
            :placeholder="$t('overall.company_name')"
          />
        </div>
        <div class="input-box">
          <input name="website" v-model="infos.website" type="text" :placeholder="$t('overall.website')" />
        </div>
        <div class="input-box">
          <input name="email" v-model="infos.email" type="email" :placeholder="$t('overall.email')" />
        </div>
        <div class="input-box">
          <input name="phone" v-model="infos.phone" type="text" :placeholder="$t('overall.phone')" />
        </div>
        <h2 class="address">{{ $t("overall.address") }}</h2>
        <AddressAutocomplete v-model="infos.address" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "GeneralInformation",
  props: ["infos"],
  data() {
    return {};
  },
});
</script>

<style></style>
