<template>
  <div class="textarea">
    <textarea
      :name="name"
      :rows="rows"
      :class="textareaClass"
      :maxLength="max"
      v-model="content"
      :placeholder="placeholder"
    />
    <span class="counter" v-if="isCounter">{{ currentLength }} / {{ max }}</span>
  </div>
</template>
<script>
export default {
  name: "Textarea",
  props: {
    value: {
      required: true,
    },
    rows: {
      default: 3,
    },
    name: {
      required: false,
      default: null,
    },
    textareaClass: {
      required: false,
      default: null,
    },
    placeholder: {
      required: false,
      default: null,
    },
    isCounter: {
      required: false,
      default: 0,
    },
    max: {
      required: false,
      default: "",
    },
  },
  computed: {
    currentLength() {
      return this.content ? this.content.length : 0;
    },
  },

  data() {
    return {
      content: "",
      counter: 0,
      isOpen: true,
    };
  },
  watch: {
    content(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.content = val;
    },
  },
  created() {
    this.content = this.value ? this.value : "";
  },
};
</script>
