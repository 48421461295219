











import Vue from "vue";
import modalBus from "@/bus/modal-bus";
import modal from "@/mixins/modal";

export default Vue.extend({
  name: "Menu",
  props: {},
  components: {},
  computed: {},
  data() {
    return {
      isOn: false,
      style: {
        width: "100%",
        height: "100vh",
      },
    };
  },
  created() {
    const _this = this;
    //@ts-ignore
    modalBus.$on("set-size", (style) => {
      _this.style = style;
    });

    modalBus.$on("toggle", (state?: boolean) => {
      //@ts-ignore
      this.isOn = state !== undefined ? state : !this.isOn;
      if (!this.isOn) {
        this.style = {
          width: "100%",
          height: "100vh",
        };
      }
    });
  },
  methods: {
    close() {
      modalBus.$emit("toggle", false);
    },
  },
  mounted() {
    document.body.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        modalBus.$emit("toggle", false);
      }
    });
    console.log("aa");
  },
});
