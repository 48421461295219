<template>
  <button :class="value ? 'active' : ''" class="toggle-button" @click="changeValue"></button>
</template>

<script>
export default {
  name: "Select",
  props: {
    value: {
      type: [Boolean, Number],
    },
  },
  methods: {
    changeValue() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style scoped>
.toggle-button.active:before {
  transform: translateX(30px);
}
</style>
