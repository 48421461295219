<template>
  <div class="date-picker" v-click-outside:isOpen :class="{ forward: isOpen }">
    <label @click="togglePicker" class="trigger">
      <slot name="button" :value="value" :date="parseBeautifulDisplay" :placeholder="placeholder">
        <span class="displayed-value" v-show="value">{{ parseBeautifulDisplay }}</span>
        <span class="displayed-value" v-show="!value">{{ placeholder }}</span>
      </slot>
    </label>
    <div class="picker" v-show="isOpen">
      <header class="flex">
        <div class="header-calendar flex">
          <button class="previous-month" @click="switchMonth(-1)">
            <svg
              enable-background="new 0 0 451.846 451.847"
              height="451.85"
              viewBox="0 0 451.846 451.847"
              width="451.85"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m345.44 248.29-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373"
              ></path>
            </svg>
          </button>
          <div class="current-month d-flex">
            <button @click="isMonthPicker = !isMonthPicker">{{ $t("overall.month" + this.month) }}</button>
            <Select v-model="year" :options="generateSelectDate(fromDate, toDate)" />
          </div>
          <button class="next-month" @click="switchMonth(1)">
            <svg
              enable-background="new 0 0 451.846 451.847"
              height="451.85"
              viewBox="0 0 451.846 451.847"
              width="451.85"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m345.44 248.29-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373"
              ></path>
            </svg>
          </button>
        </div>
      </header>
      <div class="content">
        <div class="calendar" v-show="!isMonthPicker">
          <div class="days">
            <ul>
              <li>{{ substr3($t("overall.day1")) }}</li>
              <li>{{ substr3($t("overall.day2")) }}</li>
              <li>{{ substr3($t("overall.day3")) }}</li>
              <li>{{ substr3($t("overall.day4")) }}</li>
              <li>{{ substr3($t("overall.day5")) }}</li>
              <li>{{ substr3($t("overall.day6")) }}</li>
              <li>{{ substr3($t("overall.day7")) }}</li>
            </ul>
          </div>
          <div class="figures">
            <div v-for="space in firstDay" class="blank-picker-day" :key="'datepicker-space-day' + space"></div>
            <button
              @click="selectValue(d)"
              v-for="d in daysNumber"
              class="date-picker-day"
              :class="isActive(d)"
              :key="'datepicker-day' + d"
            >
              <span>{{ d }}</span>
            </button>
          </div>
        </div>
        <div class="months-and-years" v-show="isMonthPicker">
          <ul class="flex months">
            <li v-for="m in 12" :key="'month' + m">
              <button :class="{ active: m === month }" @click="selectMonth(m)">
                {{ substr3($t("overall.month" + m)) }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Datepicker",
  props: {
    fromDate: {
      required: false,
      default() {
        return new Date().getFullYear();
      },
    },
    toDate: {
      required: false,
      default() {
        return new Date().getFullYear() + 20;
      },
    },
    value: {
      type: [String, null],
    },
    placeholder: {
      default: "",
    },
  },
  computed: {
    localMonth() {
      return this.month === 0 ? 11 : this.month - 1;
    },
    daysNumber() {
      return new Date(this.year, this.localMonth + 1, 0).getDate();
    },
    firstDay() {
      let tempDate = new Date(this.year, this.localMonth, 1).getDay();
      return tempDate === 0 ? 6 : tempDate - 1;
    },
    currentValue() {
      // $year-$month-$day format
      const reg = /^([1-2][0-9][0-9][0-9])-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1])$/;
      if (typeof this.value === "string" && reg.test(this.value)) {
        const [year, month, day] = this.value.split("-").map((val) => parseInt(val));
        return { year, month, day };
      }
      return null;
    },
    parseBeautifulDisplay() {
      if (this.currentValue !== null) {
        return (
          this.currentValue.day +
          " " +
          this.$t("overall.month" + this.currentValue.month) +
          " " +
          this.currentValue.year
        );
      }
      return "";
    },
  },

  data() {
    return {
      isOpen: false,
      isMonthPicker: false,
      month: null,
      year: null,
      day: null,
    };
  },
  watch: {
    value() {
      this.init();
    },
  },
  methods: {
    selectMonth(m) {
      this.month = m;
      this.isMonthPicker = false;
    },
    isActive(d) {
      if (
        this.currentValue !== null &&
        this.year === this.currentValue.year &&
        this.localMonth + 1 === this.currentValue.month &&
        d === this.currentValue.day
      ) {
        return {
          active: true,
        };
      }
    },
    switchMonth(sign) {
      if (sign > 0) {
        this.month = this.month === 12 ? 1 : this.month + 1;
        if (this.month === 1) this.year++;
      } else {
        this.month = this.month === 1 ? 12 : this.month - 1;
        if (this.month === 12) this.year--;
      }
    },
    substr3(val) {
      return val.substring(0, 3);
    },
    selectValue(day) {
      this.day = day;
      this.$emit("input", this.year + "-" + ("0" + this.month).slice(-2) + "-" + ("0" + this.day).slice(-2));
      this.togglePicker();
    },
    togglePicker() {
      this.isOpen = !this.isOpen;
    },
    init() {
      const reg = /^([1-2][0-9][0-9][0-9])-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1])$/;
      if (typeof this.value === "string" && reg.test(this.value)) {
        [this.year, this.month, this.day] = this.value.split("-").map((val) => parseInt(val));
      } else {
        const date = new Date();
        this.year = date.getFullYear();
        this.month = date.getMonth() + 1;
        this.day = date.getDate();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.forward {
  z-index: 9999;
}
</style>
