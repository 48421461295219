<template>
  <div class="inner-wrapper">
    <div class="u-mb-10">
      <p class="title--section u-mb-5">Property type</p>
      <ButtonsCheckList :items="types" :initial-selected-items="initialSelectedTypes" @change="onTypesCheckListChange" />
    </div>
    <div class="u-mb-10">
      <p class="title--section u-mb-10">Bedrooms</p>
      <vue-slider v-model="bedrooms_range" v-bind="{min: 0, max: 10}"/>
    </div>
    <div class="u-mb-10">
      <p class="title--section u-mb-10">Bathrooms</p>
      <vue-slider v-model="bathrooms_range" v-bind="{min: 1, max: 10}"/>
    </div>
    <div class="u-mb-10">
      <p class="title--section u-mb-10">Studio</p>
      <vue-slider v-model="studios_range" v-bind="{min: 0, max: 10}"/>
    </div>
    <div class="u-mb-10">
      <p class="title--section u-mb-5">Specifications</p>
      <ButtonsCheckList :items="specifications" :initial-selected-items="initialSelectedSpecifications" @change="onSpecificationsButtonsCheckListChange" />
    </div>
    <div class="u-mb-10">
      <p class="title--section u-mb-10">Max distance to sea</p>
      <vue-slider v-model="max_sea_distance" v-bind="{min: 1, max: 100}"/>
    </div>
    <div class="flex">
      <div class="cta save align-right" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import notifBus from "@/bus/notification-bus";
import modalBus from "@/bus/modal-bus";
import ButtonsCheckList from "@/components/ButtonsCheckList";

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import { mapGetters, mapActions } from "vuex";


export default Vue.extend({
  components: { ButtonsCheckList, VueSlider },
  data() {
    return {
      // Options bellow
      initialSelectedTypes: [],
      initialSelectedSpecifications: [],
      // Data bellow
      selectedTypes: undefined,
      selectedSpecifications: undefined,
      bedrooms_range: [1, 7],
      bathrooms_range: [1, 7],
      studios_range: [0, 0],
      max_sea_distance: 50,
      monthsForSelect: [],
      yearsForSelect: [],
    };
  },
  props: ["data"],
  computed: {
    ...mapGetters({
      specifications: "searchCriteriaOptions/propertiesSpecifications",
      types: "searchCriteriaOptions/propertiesTypes",
    }),
  },
  methods: {
    ...mapActions({
      fetchOptions: "searchCriteriaOptions/fetchOptions",
    }),
    save() {
      const data = {
        id: this.data.id,
        types: this.selectedTypes,
        specifications: this.selectedSpecifications,
        bedroom_min: this.bedrooms_range[0],
        bedroom_max: this.bedrooms_range[1],
        bathroom_min: this.bathrooms_range[0],
        bathroom_max: this.bathrooms_range[1],
        studio_min: this.studios_range[0],
        studio_max: this.studios_range[1],
        max_sea_distance: this.max_sea_distance,
      };
      modalBus.$emit("save-search-criteria", data);
      modalBus.sendAndClose();
    },
    onTypesCheckListChange({ selectedItems }){
      this.selectedTypes = selectedItems;
    },
    onSpecificationsButtonsCheckListChange({ selectedItems }){
      this.selectedSpecifications = selectedItems;
    },
  },
  async created(){
    await this.fetchOptions();
    this.initialSelectedTypes = this.data.types && this.data.types.length ? this.data.types : [];
    this.initialSelectedSpecifications = this.data.specifications && this.data.specifications ? this.data.specifications : [];
    this.selectedTypes = this.initialSelectedTypes;
    this.selectedSpecifications = this.initialSelectedSpecifications;

    if ( this.data.bedroom_min !== undefined ) {
      this.bedrooms_range = [ this.data.bedroom_min !== null ? this.data.bedroom_min : 0, this.data.bedroom_max !== null ? this.data.bedroom_max : 0 ];
    }
    if ( this.data.bathroom_min !== undefined ) {
      this.bathrooms_range = [ this.data.bathroom_min !== null ? this.data.bathroom_min : 0, this.data.bathroom_max !== null ? this.data.bathroom_max : 0 ];
    }
    if ( this.data.studio_min !== undefined ) {
      this.studios_range = [ this.data.studio_min !== null ? this.data.studio_min : 0, this.data.studio_max !== null ? this.data.studio_max : 0 ];
    }
    this.max_sea_distance = this.data.max_sea_distance ? parseInt(this.data.max_sea_distance) : null;
  }
});
</script>
