export default {
  clients: "Clients",
  edition: "Client Edition",
  pref_language: "Language",
  add_contact: "Add a contact",
  timeline: "Timeline",
  modify: "Edit Client",
  back: "Back to clients",
  follow_up: "Follow up",
  historical: "Historical",
  create: "Create client",
  is_activated: "Client activated",
  client_infos: "Client informations",
  properties_suggestion: "Properties suggestions",
  source: "Source",

  add_event: "Add event",
  add_deal: "Add deal",
  event_type: "Event type",
  add_follow_up: "Add follow up",

  properties_visit: "Visit",
  appointment: "Appointment",
  note: "Note",
  add_email: "Add email",
  add_phone: "Add phone",
  popup: {
    add_title: "Add title",
    add_description: "Ajouter description",
    add_time: "Add time",
  },
  no_client: "No client",
  no_event: "No event",

  notifications: {
    saved: "Client saved",
  },
  lead: "Lead",
  booking: "Booking",
  buying: "Buying",
  trading: "Trading",
  searching: "Searching",
  specifications: "Specifications",
  priority_level: "Priority level",
  very_low: "Very low",
  low: "Low",
  medium: "medium",
  high: "High",
  very_high: "Very high",

  deal_saved: "Deal saved",
  event_saved: "Event saved",
  event_deleted: "Event deleted",
  is_already_done: "Already done",
  associated_properties: "Associated properties",
  state: "State",
  expected_date: "Expected Date",
  expected_price: "Expected Price",
  professional: "Professional",
  private: "Private",
  precise_time: "Precise Time",
  remove_time: "Remove Time",
  add_location: "Add location",
  inquiry: "Inquiry",
  favorite_properties: "Favorite Properties",
  filtered_properties: "Filtered Properties",
  close_deal: "Close Deal",
  rent: "Rent",
  buy: "Buy",
  achieved: "Achieved",
  refused: "Refused",
  find_properties: "Find properties",
  selected_properties: "Chosen properties",
  new_deal: "New Deal",
  associated_deal: "Associated Deal",
  selling_price: "Selling price",
  close_date: "Closing Date",
  sold_price: "Sold price",

  new: "New",
  not_urgent: "Not Urgent",
  warm: "Warm",
  very_urgent: "Very Urgent",
  booked: "Booked",
  aftersales: "Aftersales",

  no_deal_yet: "You don't have any deal yet",
};
