import Vue from "vue";
import VueRouter from "vue-router";
import { noGuardRoutes, routes } from "@/router/routes";
import beforeEachRoutesRedirectIfUserDontHaveCapabilities from "@/helpers/routes/beforeEachRoutesRedirectIfUserDontHaveCapabilities";
import store from "@/store";
import whenUserLoadedMaybeRedirectToFirstRoute from "@/helpers/routes/whenUserLoadedMaybeRedirectToFirstRoute";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const firstTime = true;


router.beforeEach((to, from, next) => {
  const toName: string = to.name ? to.name : "";
  if (firstTime) {
    store.commit("setFirstLoadedRoute", { name: toName, path: to.path });
  }
  if (noGuardRoutes.includes(toName)) {
    next();
  } else {
    if (Vue.$cookies.get("jkt")) {
      beforeEachRoutesRedirectIfUserDontHaveCapabilities(to, from, next);
    } else {
      next({ name: "login" });
    }
  }
  next();
});

whenUserLoadedMaybeRedirectToFirstRoute();

export default router;
