import Vue from "vue";

export default {
  //@ts-ignore
  TOGGLE_MENU(state, payload: Boolean) {
    Vue.set(state.global, "isMenu", payload);
  },

  //@ts-ignore
  setLanguages(state, payload) {
    Vue.set(state.global, "availableLanguages", payload);
  },

  //@ts-ignore
  setProfile(state, payload) {
    Vue.set(state.global, "user", payload);
  },

  //@ts-ignore
  emptyUserObject(state, payload) {
    state.global.user = undefined;
  },

  //@ts-ignore
  setSettings(state, payload) {
    state.settings = {
      ...state.settings,
      company_name: payload.company_name,
      website: payload.website,
      phone: payload.phone,
      email: payload.email,
    };
  },
  setFirstLoadedRoute(state: any, payload: any) {
    state.global.firstLoadedRoute = payload;
  },
};
