


















import Vue from "vue";
import Menu from "./components/Menu.vue";
import Notification from "./components/Notification.vue";
import mixModal from "./mixins/modal";

export default Vue.extend({
  components: { Menu, Notification },
  name: "App",
  mixins: [mixModal],
  data() {
    return {};
  },
  methods: {
    toggleMenu() {
      this.$store.commit("TOGGLE_MENU", !this.$store.state.global.isMenu);
    },
  },
  computed: {
    appClassName() {
      return "page-" + this.$route.name;
    },
  },
  created() {
    //@ts-ignore
    if (Vue.$cookies.get("jkt")) {
      //@ts-ignore
      this.$axios.get("/api/users/profile").then(
        (res: any) => {
          this.$store.dispatch("setupProfile", res.data);
        },
        //@ts-ignore
        (error: any) => {
          console.log("Call to /api/users/profile failed", error.response);
        },
      );
      this.$store.dispatch("setupLanguages");
    }
  },
});
