<template>
  <div class="select select-search" :class="{ forward: isOpen }" v-click-outside:isOpen="selectFirst">
    <input
      :placeholder="placeholder ? placeholder : 'Enter something'"
      class="selected"
      @keypress.enter="selectFirst"
      v-model="search"
      @click="toggleOptions"
    />
    <ul class="options" v-show="isOpen">
      <li
        :class="currentValue === o ? 'is-selected' : ''"
        v-for="(o, i) in displayedOptions"
        :key="o + i"
        @click="changeValue(o)"
      >
        {{ o.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SelectSearch",
  props: {
    nullable: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    multi: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: [String, Number],
    },
  },
  watch: {
    search(search) {
      this.displayedOptions = this.options.filter((o) => {
        return o.name.toLowerCase().includes(search.toLowerCase());
      });
      if ( !this.isFirstSearch ) {
        this.isOpen = true;
        this.isFirstSearch = false;
      }
    },
    value(newValue) {
      let matchingObject = this.options.find((el) => {
        return el.value === newValue;
      });

      if (matchingObject !== undefined) {
        this.changeValue(matchingObject);
      } else {
        /* console.warn("Value '" + newValue + "' does not match any option in ");
        console.warn(this.$el);*/
      }
    },
    options() {
      let selected = false;
      for (let o of this.options) {
        if (this.value === o.value) {
          selected = true;
          this.changeValue(o);
          break;
        } else if (o.hasOwnProperty("selected") && o.selected) {
          this.changeValue(o);
          selected = true;
        }
      }
    },
  },
  data() {
    return {
      currentValue: {
        name: "",
        value: "",
      },
      displayedOptions: [],
      search: "",
      lock: 0,
      isOpen: false,
      isFirstSearch: true,
    };
  },
  methods: {
    selectFirst() {
      if (this.search.trim() !== "") {
        if (this.displayedOptions.length > 0) {
          this.displayedOptions !== undefined || this.displayedOptions || (null && this.displayedOptions.length <= 0)
            ? this.changeValue(this.displayedOptions[0])
            : this.$emit("input", this.displayedOptions[0]);
        } else {
          this.search = this.value.name ? this.value.name : "";
        }
      } else {
        this.search = "";
        this.$emit("input", null);
      }
    },
    toggleOptions() {
      this.isOpen = !this.isOpen;
    },
    changeValue(val = null) {
      this.currentValue = val;
      this.isOpen = false;
      this.search = this.currentValue.name.charAt(0).toUpperCase() + this.currentValue.name.substring(1);
      this.$emit("input", this.currentValue.value);
    },
  },
  mounted() {
    this.displayedOptions = this.options;
    let selected = false;
    for (let option of this.options) {
      if (this.value === option.value) {
        selected = true;
        this.changeValue(option);
        break;
      } else if (option.hasOwnProperty("selected") && option.selected) {
        this.changeValue(option);
        selected = true;
      }
    }
    if (!selected && this.options.length > 0) {
      if ( this.selectDefault && this.options.length ) {
        this.changeValue(this.options[0])
      } else {
        this.$emit("input", null);
      }
    }
  },
};
</script>

<style scoped>
.forward {
  z-index: 9999;
}
</style>
