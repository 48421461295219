import moment from "moment";
import Vue from "vue";

export const ADD_EVENT = "ADD_EVENT";
export const ADD_EVENTS = "ADD_EVENTS";
export const EDIT_EVENT = "EDIT_EVENT";
export const EDIT_TEMPORARY_EVENT = "EDIT_TEMPORARY_EVENT";
export const FETCH_EVENTS = "FETCH_EVENTS";
export const ADD_RESOURCES = "ADD_RESOURCES";
export const SET_CURRENT_AGENT_ID = "SET_CURRENT_AGENT_ID";
export const SET_CLIENT_EVENTS = "SET_CLIENT_EVENTS";
export const ADD_CLIENT_EVENT = "ADD_CLIENT_EVENT";
export const DELETE_CLIENT_EVENT = "DELETE_CLIENT_EVENT";
export const EDIT_CLIENT_EVENT = "EDIT_CLIENT_EVENT";
export const ADD_EVENTS_FROM_CURRENT_AGENT = "ADD_EVENTS_FROM_CURRENT_AGENT";

import { AxiosPlugin } from "@/plugins/axios";

Vue.use(AxiosPlugin);
const v = new Vue();

// TODO: continue temporary event

// initial state
const state = {
  temporaryEvent: false,
  resources: [
    {
      id: "call",
      title: "Call",
      eventBackgroundColor: "rgba(82,232,196, 1)",
      eventBorderColor: "transparent",
    },
    {
      id: "email",
      title: "Email",
      eventBackgroundColor: "rgba(251,147,145, 1)",
      eventBorderColor: "transparent",
    },
    {
      id: "office_appointment",
      title: "Office Appointment",
      eventBackgroundColor: "rgba(76,132,255, 1)",
      eventBorderColor: "transparent",
    },
    {
      id: "home_visit_be",
      title: "Home Visit BE",
      eventBackgroundColor: "rgba(87,205,255, 1)",
      eventBorderColor: "transparent",
    },
    {
      id: "viewing",
      title: "Viewing",
      eventBackgroundColor: "rgba(241,179,30, 1)",
      eventBorderColor: "transparent",
    },
    {
      id: "inspection_trip",
      title: "Inspection Trip",
      eventBackgroundColor: "rgb(154,153,241)",
      eventBorderColor: "transparent",
    },
    {
      id: "aftersales",
      title: "Aftersales",
      eventBackgroundColor: "rgb(255,170,252)",
      eventBorderColor: "transparent",
    },
    {
      id: "notary",
      title: "Notary",
      eventBackgroundColor: "rgb(255,74,96)",
      eventBorderColor: "transparent",
    },
  ],
  events: [],
  clientEvents: [], // events in the single client page
  currentAgentId: null,
  eventsFromCurrentAgent: [],
};

// getters
const getters = {
  //@ts-ignore
  events: (state) => state.events,
  //@ts-ignore
  resources: (state) => state.resources,
  //@ts-ignore
  temporaryEvent: (state) => state.temporaryEvent,
  //@ts-ignore
  currentAgentId: (state) => state.currentAgentId,
  //@ts-ignore
  eventsFromCurrentAgent: (state) => state.eventsFromCurrentAgent,
  //@ts-ignore
  clientEvents: (state) => state.clientEvents,
};

//@ts-ignore
const _filterEventsForCurrentAgentId = (events, currentAgentId) => {
  if (currentAgentId != null) {
    if (events && events.length > 0) {
      //@ts-ignore
      events = events.filter((event) => {
        if (event.backendEvent.collaborators) {
          //@ts-ignore
          const founds = event.backendEvent.collaborators.filter(({ id }) => id === currentAgentId);
          return founds && founds.length;
        }
        return false;
      });
    }
  }
  return events;
};
//@ts-ignore
const _convertEventForCalendar = (event) => {
  let start = event.from_date;
  let end = event.to_date;

  if (event.is_time) {
    start += ` ${event.from_time}`;
    end += ` ${event.to_time}`;
  } else {
    const startMoment = moment(event.to_date, "YYYY-MM-DD");
    end = startMoment.add(1, "days").format("YYYY-MM-DD");
  }

  let title = `${event.title}`;

  const findResource: any = state.resources.find((item: any) => item.id === event.type);

  return {
    id: event.id,
    start,
    end,
    type: event.type,
    backgroundColor: findResource ? findResource.eventBackgroundColor : undefined,
    borderColor: "transparent",
    resourceId: event.type,
    title,
    backendEvent: event,
    backendEventCollaborators: event.collaborators,
  };
};

// actions
const actions = {
  async fetchEvents({ commit, state }: any, { from = 0 }: { from?: Number } = {}): Promise<any> {
    //@ts-ignore
    const response = await v.$axios.get(`/api/events?from=${from.toString()}`);
    if (response.status === 200) {
      let eventsRaw = response.data.events;
      let events: any[] = [];

      eventsRaw.forEach((event: any) => {
        if (event.is_in_agenda) {
          events.push(_convertEventForCalendar(event));
        }
      });

      commit(ADD_EVENTS, events);
      commit(ADD_EVENTS_FROM_CURRENT_AGENT, _filterEventsForCurrentAgentId(events, state.currentAgentId));
    } else if (response.status === 429) {
      console.log("Error 429", response);
    } else {
      console.log("Fetch events error", response.status);
    }
  },
  async saveEvent({ commit, state }: any, { event, creator }: any): Promise<any> {
    if (!event.is_time) {
      event.to_time = "00:00:00";
      event.from_time = "00:00:00";
    }

    //@ts-ignore
    const response = await v.$axios.post("/api/events", {
      event,
      creator,
    });

    const savedEvent = response.data;

    commit(EDIT_CLIENT_EVENT, savedEvent);

    if (event.token) {
      commit(EDIT_EVENT, _convertEventForCalendar(event));
    }

    return response;
  },
  async deleteEvent({ commit, state }: any, { token }: any): Promise<any> {
    //@ts-ignore
    const response = await v.$axios.delete("/api/events/" + token);
    // TODO: delete current client event
    return response;
  },
  setCurrentAgentId({ commit, state }: any, id: any) {
    commit(SET_CURRENT_AGENT_ID, id);
    const events = _filterEventsForCurrentAgentId(state.events, state.currentAgentId);
    commit(ADD_EVENTS_FROM_CURRENT_AGENT, events);
  },
  setClientEvents({ commit, state }: any, events: any) {
    commit(SET_CLIENT_EVENTS, events);
  },
  addClientEvent({ commit, state }: any, event: any) {
    commit(ADD_CLIENT_EVENT, event);
  },
  deleteClientEvent({ commit, state }: any, event: any) {
    commit(DELETE_CLIENT_EVENT, event);
  },
  resetEventsForLogout({ commit, state }: any, event: any) {
    commit(ADD_EVENTS, []);
    commit(ADD_EVENTS_FROM_CURRENT_AGENT, []);
    commit(SET_CLIENT_EVENTS, []);
  },
};

const mutations = {
  //@ts-ignore
  [EDIT_CLIENT_EVENT](state, newEvent) {
    let index = state.clientEvents.findIndex((event: any) => event.token === newEvent.token);
    if (index > -1) {
      state.clientEvents.splice(index, 1, { ...newEvent });
    }
  },
  //@ts-ignore
  [DELETE_CLIENT_EVENT](state, { token }) {
    let index = state.clientEvents.findIndex((event: any) => event.token === token);
    if (index > -1) {
      state.clientEvents.splice(index, 1);
    }
  },
  //@ts-ignore
  [ADD_CLIENT_EVENT](state, newEvent) {
    let index = state.clientEvents.findIndex((event: any) => event.token === newEvent.token);
    if (index > -1) {
      state.clientEvents.splice(index, 1, { ...newEvent });
    } else {
      state.clientEvents.push(newEvent);
    }
  },
  //@ts-ignore
  [SET_CLIENT_EVENTS](state, events) {
    state.clientEvents = events;
  },
  //@ts-ignore
  [SET_CURRENT_AGENT_ID](state, id) {
    state.currentAgentId = id;
  },
  //@ts-ignore
  [ADD_EVENT](state, event) {
    state.events.push(event);
  },
  //@ts-ignore
  [ADD_RESOURCES](state, resources) {
    state.resources = resources;
  },
  //@ts-ignore
  [ADD_EVENTS](state, events) {
    state.events = events;
  },
  //@ts-ignore
  [ADD_EVENTS_FROM_CURRENT_AGENT](state, events) {
    state.eventsFromCurrentAgent = events;
  },
  //@ts-ignore
  [EDIT_EVENT](state, event) {
    //@ts-ignore
    let index = state.events.findIndex(({ id }) => id === event.id);
    if (index > -1) {
      state.events.splice(index, 1, { ...state.events[index], ...event });
    }
  },
  //@ts-ignore
  [EDIT_TEMPORARY_EVENT](state, event) {
    state.temporaryEvent = { ...(state.temporaryEvent ? state.temporaryEvent : {}), ...event };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
