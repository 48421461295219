<template>
  <div class="event-card client-add-event">
    <section class="tab-content">
      <input class="event-card__title" v-model="event.title" :placeholder="$t('overall.add_title')" />
      <Select v-model="event.type" class="client-add-event__select" :options="types" />
      <div class="input-box flex">
        <div class="input-wrapper-icon">
          <dateSVG class="input-wrapper-icon__svg" style="fill: #27D3A6;" />
          <div class="input-wrapper-icon__inputs">
            <div class="day-picker">
              <Datepicker v-model="event.from_date" :placeholder="$t('overall.pick_date')" />
            </div>
            <span class="separator" v-if="!event.is_time">-</span>
            <template v-if="event.is_time">
              <div class="d-flex time-picker">
                <div class="from-time">
                  <InputTime v-model="event.from_time" />
                </div>
                <span class="separator">-</span>
                <div class="to-time">
                  <InputTime v-model="event.to_time" />
                </div>
              </div>
            </template>
            <div class="day-picker">
              <Datepicker v-model="event.to_date" :placeholder="$t('overall.pick_date')" />
            </div>
          </div>
        </div>
        <div class="flex all-day" style="margin-top: 10px;">
          <button @click="toggleTime" class="precise-time colored">
            <span>{{ event.is_time ? $t("clients.remove_time") : "+" + $t("clients.precise_time") }}</span>
          </button>
        </div>
      </div>
      <!-- client -->
      <div class="u-flex u-flex-align-i-center u-mb-20">
        <div class="input-wrapper-icon u-mr-20" v-if="data.addClient" style="width: auto">
          <personSVG class="input-wrapper-icon__svg" style="stroke: #55CCFF;" />
          <SelectSearch
            placeholder="Add client"
            v-model="event.client"
            :selectDefault="false"
            :options="clientsList"
            v-if="clientsList.length"
          />
        </div>
        <button v-if="data.addClient && event.client" class="cta" @click="goToClientPage">
          <arrowSVG />
          Client page
        </button>
      </div>
      <div
        class="properties"
        style="margin-top: 10px;"
        v-if="(event.type === 'viewing' || event.type === 'inspection_trip') && event.client"
      >
        <Autocomplete
          :placeholder="$t('projects.add_property')"
          @save="addProperty"
          @search="$store.dispatch('properties/search', $event)"
          :options="$store.getters['properties/options']"
          :clearOnSave="true"
        />
        <ul class="properties-list u-flex u-flex-wrap u-mb-20">
          <li v-for="p in event.properties" :key="p.token" class="property-tag u-mt-10 u-mr-10">
            <div @click="goToPropertyPage(p)" class="property-tag__img-wrapper">
              <img
                :class="{ no_image: !p.first_image }"
                :src="p.first_image ? p.first_image : '/img/svg/house.svg'"
                alt=""
              />
            </div>
            <div @click="goToPropertyPage(p)" class="property-tag__text">{{ p.reference }}</div>
            <button class="property-tag__cross" @click="removeProperty(p)">X</button>
          </li>
        </ul>
      </div>
      <div class="input-wrapper-icon" style="margin-bottom: 20px;">
        <locationSVG class="input-wrapper-icon__svg" style="fill: #EBDB47;" />
        <div class="address" style="width: 100%; display: flex;">
          <button v-if="!is_address" class="colored" @click="is_address = !is_address">
            {{ $t("clients.add_location") }}
          </button>
          <AddressAutocomplete :placeholder="$t('overall.add_address')" v-model="event.address" v-else />
        </div>
      </div>
      <div class="input-wrapper-icon _align-top">
        <personSVG class="input-wrapper-icon__svg" style="stroke: #55CCFF;" />
        <CollaboratorHandler
          @change="handleCollaboratorsChange"
          :assigned-agents="event.collaborators"
          :agents="agents"
        />
      </div>
      <div class="input-box" style="margin-top: 20px;">
        <div class="flex">
          <Textarea v-model="event.description" is-counter="true" max="200" />
        </div>
      </div>
      <div v-if="event.creator" class="u-mb-10">
        Created by
        <span class="date" v-if="event.creator">{{ formatFullname(event.creator) }} - </span>
        <span class="date">{{ !event.created_at ? todayDateString : parseLaravelDateTime(event.created_at) }}</span>
      </div>
      <div class="flex flex-align-items-center main-actions">
        <div>
          <button class="flex" @click="event.is_in_agenda = !event.is_in_agenda">
            <Checkbox :value="event.is_in_agenda" />
            <label>Put this action in the agenda</label>
          </button>
          <button class="flex" @click="event.is_done = !event.is_done">
            <Checkbox :value="event.is_done" />
            <label>Already done</label>
          </button>
        </div>
        <div class="flex">
          <div
            v-if="!this.data.isNewEvent"
            class="cta _danger-border _svg-right align-right"
            :style="{ 'margin-right': '12px' }"
            @click="showDeletePopup"
            :class="{ disabled: !areDatesAndTimesOk() }"
          >
            {{ $t("overall.delete") }}
            <crossSVG />
          </div>
          <div class="cta save _svg-right align-right" @click="saveEvent" :class="{ disabled: !areDatesAndTimesOk() }">
            {{ !this.data.isNewEvent ? $t("overall.update") : $t("overall.create") }}
            <checkSVG />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import Vue from "vue";
import modalBus from "@/bus/modal-bus";
import notifBus from "@/bus/notification-bus";
import modal from "@/mixins/modal";
import dateSVG from "@/assets/img/date.svg";
import personSVG from "@/assets/img/person.svg";
import locationSVG from "@/assets/img/location.svg";
import checkSVG from "@/assets/img/check-small.svg";
import CollaboratorHandler from "@/components/CollaboratorHandler";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { AccountCapabilityCanSeeAgendaFromAll, AccountCapabilityCanSeeOwnAgenda } from "@/types";
import moment from "moment";
import InputTime from "@/components/InputTime";

export default Vue.extend({
  name: "AddEvent",
  components: {
    InputTime,
    dateSVG,
    personSVG,
    locationSVG,
    checkSVG,
    CollaboratorHandler,
  },
  props: ["data", "isNewEvent"],
  data() {
    return {
      clientsList: [],
      agents: [],
      event: {
        id: null,
        collaborators: undefined,
        client: null,
        token: null,
        type: "email",
        title: null,
        description: null,
        from_date: null,
        to_date: null,
        is_time: false,
        is_done: false,
        is_in_agenda: true,
        from_time: null,
        to_time: null,
        deal: null,
        address: null,
        properties: [],
      },
      address: null,
      deals: [],
      is_address: false,
    };
  },
  computed: {
    ...mapState({
      propertiesFound: (state) => state.properties.found,
    }),
    ...mapGetters({
      optionsProperties: "properties/options",
      resources: "agenda/resources",
    }),
    user() {
      return this.$store.state.global.user;
    },
    selectDeal() {
      return this.deals.map((el) => {
        return { name: el.name, value: el.id };
      });
    },
    types() {
      let array = [];
      if (this.resources && this.resources.length) {
        array = this.resources.map((item) => {
          return {
            name: item.title,
            value: item.id,
          };
        });
      }
      return array;
    },
    canShowFilters() {
      if (!this.user || !this.user.capabilities.length) {
        return false;
      }
      return (
        this.user.capabilities.includes(AccountCapabilityCanSeeOwnAgenda) &&
        this.user.capabilities.includes(AccountCapabilityCanSeeAgendaFromAll)
      );
    },
  },
  methods: {
    ...mapActions({
      _fetchEvents: "agenda/fetchEvents",
      _saveEvent: "agenda/saveEvent",
      _deleteEvent: "agenda/deleteEvent",
      addClientEvent: "agenda/addClientEvent",
      deleteClientEvent: "agenda/deleteClientEvent",
      searchProperties: "properties/search",
      setProperties: "properties/set",
    }),
    toggleTime() {
      if (this.event.is_time) {
        this.event.is_time = false;
        this.event.from_time = "00:00:00";
        this.event.to_time = "00:00:00";
      } else {
        this.event.is_time = true;
      }
    },
    handleCollaboratorsChange({ collaborators }) {
      this.event.collaborators = collaborators;
    },
    areDatesAndTimesOk() {
      let [fromYear, fromMonth, fromDay] = this.event.from_date.split("-").map((val) => parseInt(val));
      let [toYear, toMonth, toDay] = this.event.to_date.split("-").map((val) => parseInt(val));

      if (toYear < fromYear) {
        return false;
      } else if (toYear === fromYear) {
        if (toMonth < fromMonth) {
          return false;
        } else if (toMonth === fromMonth && toDay < fromDay) {
          return false;
        }
      }
      return true;
    },
    addProperty(value) {
      const isAlready = this.event.properties.find((el) => {
        if (el.id === value.value) return el;
      });
      if (!isAlready)
        this.event.properties.push(
          this.propertiesFound.find((el) => {
            if (el.id === value.value) return el;
          }),
        );
    },
    removeProperty(property) {
      const indexFound = this.event.properties.findIndex((el) => el.id === property.id);
      if (indexFound > -1) {
        this.event.properties.splice(indexFound, 1);
      }
    },
    changeTab(tab) {
      this.event.type = tab;
    },
    async saveEvent() {
      const response = await this._saveEvent({ event: this.event, creator: this.user.id });
      if (response.status === 200) {
        this.event.id = response.data.id;
        this.event.token = response.data.token;
        if (response.data.client) {
          this.event.client = response.data.client.id;
        }
        notifBus.$emit("show", {
          status: "success",
          message: this.$t("clients.event_saved"),
        });
        modalBus.$emit("add-event", this.event);
        this.addClientEvent(this.event);
        this.fetchEvents();
      } else {
        console.error("Couldn't save the event", response, response.status, response.statusText);
      }
      modalBus.sendAndClose();
    },
    showDeletePopup() {
      const confirm = window.confirm("Are you sure you want to delete this event ?");
      if (confirm) {
        this.deleteEvent();
      }
    },
    async fetchEvents() {
      // If user have only access to its own events, filter
      let from = "";
      if (!this.canShowFilters) {
        from = this.user.id;
      }
      return this._fetchEvents({ from });
    },
    async deleteEvent() {
      await this._deleteEvent({ token: this.event.token });
      this.deleteClientEvent(this.event);
      notifBus.$emit("show", {
        status: "success",
        message: this.$t("clients.event_deleted"),
      });
      modalBus.sendAndClose();
      this.fetchEvents();
    },
    goToClientPage() {
      const clientID = this.event.client.id ? this.event.client.id : this.event.client;
      const foundClientFromList = this.clientsList.find((item) => item.data.id === clientID);
      const client = foundClientFromList && foundClientFromList.data ? foundClientFromList.data : undefined;
      if (client && client.token) {
        this.$router.push({ name: "edit-client", params: { token: client.token } });
        modalBus.sendAndClose();
      } else {
        console.log("no client found in list", this.event);
      }
    },
    goToPropertyPage(property) {
      this.$router.push({ name: "edit-property", params: { token: property.token } });
      modalBus.sendAndClose();
    },
    fetchClients() {
      // If user have only access to its own events, filter
      let from = "";
      if (!this.canShowFilters) {
        from = this.user.id;
      }
      const url = `/api/clients?from=${from}`;
      this.$axios.get(url).then((res) => {
        this.clientsList = res.data.map((el) => {
          return {
            name: el.first_name + " " + el.last_name,
            value: el.id,
            data: el,
          };
        });
      });
    },
  },
  watch: {
    "event.is_in_agenda": {
      //immediate: true,
      handler(newValue, oldValue) {
        if (!newValue) {
          this.event.is_done = true;
        }
      },
    },
    "event.from_date"() {
      if (!this.areDatesAndTimesOk()) {
        if (this.event.to_date !== this.event.from_date) {
          this.event.to_date = this.event.from_date;
        }
      }
    },
    "event.to_date"() {
      if (!this.areDatesAndTimesOk()) {
        if (this.event.to_date !== this.event.from_date) {
          this.event.from_date = this.event.to_date;
        }
      }
    },
    "event.type": {
      immediate: true,
      handler(type) {
        if (this.data.isNewEvent) {
          switch (type) {
            case "call":
              this.event.title = "Make a call";
              break;
            case "email":
              this.event.title = "Send an email";
              break;
            case "office_appointment":
              this.event.title = "Make an Office Appointment";
              break;
            case "home_visit_be":
              this.event.title = "Visit Home in Belgium";
              break;
            case "viewing":
              this.event.title = "Viewing";
              break;
            case "inspection_trip":
              this.event.title = "Inspection Trip";
              break;
            case "aftersales":
              this.event.title = "Aftersales";
              break;
            case "notary":
              this.event.title = "Notary Appointment";
              break;
          }
        }
      },
    },
  },
  created() {
    if (this.data.addClient) {
      this.fetchClients();
    }
    if (this.data.event) {
      this.event = JSON.parse(JSON.stringify(this.data.event));
      // Check if we should active the "time" inputs
      const from_time = this.event.from_time;
      const to_time = this.event.to_time;
      const from_time_moment = moment(from_time, "HH:mm:ss");
      const to_time_moment = moment(to_time, "HH:mm:ss");
      if (from_time_moment.isValid() && to_time_moment.isValid()) {
        if (to_time !== "00:00:00" && to_time !== "00:00:00") {
          this.event.is_time = true;
        }
      }
    }
    if (!this.event.from_date) {
      this.event.from_date = this.data.from_date ? this.data.from_date : this.generateTodayDate();
    }
    if (!this.event.from_time) {
      this.event.from_time = this.generateTodayTime();
    }
    if (!this.event.to_time) {
      this.event.to_time = this.generateTodayTime();
    }
    if (!this.event.to_date) {
      this.event.to_date = this.data.to_date ? this.data.to_date : this.generateTodayDate();
    }
    if (this.data.client) {
      this.event.client = this.data.client.id;
    } else {
      if (this.event.client && typeof this.event.client === "object") {
        this.event.client = this.event.client.id;
      }
    }
    if (this.data.deals) this.deals = this.data.deals;
    this.is_address = !!this.event.address;
    if (this.data.isNewEvent) {
      const { token, birthday, email, first_name, id, last_name, phone, profile_picture, username } = this.user;
      const userCollaborator = {
        token,
        birthday,
        email,
        first_name,
        id,
        last_name,
        phone,
        profile_picture,
        username,
      };
      this.event.collaborators = [userCollaborator];
    }
    this.$axios.get("/api/users").then((res) => {
      this.agents = res.data;
    });
  },
});
</script>
