<template>
  <div class="inner-wrapper" style="text-align: center">
    <p>{{ message }}</p>
    <div class="d-flex buttons center">
      <button class="cta cancel" @click="close">Cancel</button>
      <button class="cta go" @click="confirm">Confirm</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import modalBus from "@/bus/modal-bus";
export default Vue.extend({
  props: {
    data: Object,
  },
  data() {
    return {
      message: "Are you sure ?",
      methodToCall: function() {
        console.log("OK it's called but nothing happens...");
      },
    };
  },
  methods: {
    close() {
      modalBus.$emit("toggle", false);
    },
    confirm() {
      this.methodToCall();
      modalBus.$emit("toggle", false);
    },
  },
  created() {
    this.message = this.data.message ? this.data.message : this.message;
    this.methodToCall = this.data.methodToCall ? this.data.methodToCall : this.methodToCall;
  },
});
</script>
