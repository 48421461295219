<template>
  <div class="input">
    <input :type="type" :name="name" :placeholder="placeholder" :value="content" @keyup="changeValue" />
  </div>
</template>
<script>
export default {
  name: "Input",
  props: {
    value: {
      required: true,
    },
    name: {
      required: false,
      default: null,
    },
    type: {
      required: false,
      default: "text",
    },
    inputClass: {
      required: false,
      default: null,
    },
    placeholder: {
      required: false,
      default: null,
    },
  },
  computed: {},

  methods: {
    changeValue(event) {
      //@ts-ignore
      const string = event.target.value.split(" ").join("");
      this.$emit("input", string);
    },
    splitNumber(val) {
      val = val.toString();
      const separator = " ";
      let stringWithSeparator = "",
        counter = 1;
      for (let i = val.length; i > 0; i--) {
        counter++;
        if (counter % 4 === 0) {
          counter = 1;
          stringWithSeparator = separator + val.charAt(i - 1) + stringWithSeparator;
        } else {
          stringWithSeparator = val.charAt(i - 1) + stringWithSeparator;
        }
      }
      return stringWithSeparator.trim();
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          console.log(val.length);
          this.content = this.splitNumber(val);
        } else {
          this.content = val;
        }
      },
    },
  },

  data() {
    return {
      content: null,
    };
  },
};
</script>
