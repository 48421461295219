import Vue from "vue";

const ADD_USERS = "ADD_USERS";

import { AxiosPlugin } from "@/plugins/axios";

Vue.use(AxiosPlugin);
const v = new Vue();

// initial state
const state = {
  users: [],
};

// getters
const getters = {
  //@ts-ignore
  users: (state) => state.users,
};

// actions
const actions = {
  async fetchUsers({ commit, state }: any): Promise<any> {
    //@ts-ignore
    const response = await v.$axios.get("/api/users");
    if (response.status === 200) {
      let users = response.data;
      commit(ADD_USERS, users);
    } else if (response.status === 429) {
      console.log("Error 429", response);
    } else {
      console.log("Fetch users error", response.status);
    }
  },
};

const mutations = {
  //@ts-ignore
  [ADD_USERS](state, users) {
    state.users = users;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
