<template>
  <div class="social">
    <div class="input-box">
      <h2>Facebook</h2>
      <input type="text" v-model="social.facebook" placeholder="https://www.facebook.com/axioscrm" />
    </div>
    <div class="input-box">
      <h2>Instagram</h2>
      <input type="text" v-model="social.instagram" placeholder="https://www.instagram.com/axioscrm" />
    </div>
    <div class="input-box">
      <h2>Twitter</h2>
      <input type="text" v-model="social.twitter" placeholder="https://twitter.com/axioscrm" />
    </div>
    <div class="input-box">
      <h2>Linkedin</h2>
      <input type="text" v-model="social.linkedin" placeholder="https://www.linkedin.com/axioscrm" />
    </div>
    <div class="input-box">
      <h2>Youtube</h2>
      <input type="text" v-model="social.youtube" placeholder="https://www.youtube.com/axioscrm" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "GeneralInformation",
  props: ["social"],
});
</script>

<style></style>
