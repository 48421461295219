<template>
  <button :class="{ active: value }" class="tag" @click="toggleValue">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "Tag",
  props: {
    value: {
      type: [Boolean, Number],
    },
  },
  methods: {
    toggleValue() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style scoped></style>
