import store from "@/store";
import Select from "@/components/Select.vue";
import Header from "@/components/Header.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import notifBus from "@/bus/notification-bus";
import moment from "moment-timezone";

export const mix = {
  components: { Select, Header },
  filters: {
    //@ts-ignore
    currency(val) {
      return val;
    },
    //@ts-ignore
    splitNumber(val) {
      val = val.toString();
      const separator = " ";
      let stringWithSeparator = "",
        counter = 1;
      for (let i = val.length; i > 0; i--) {
        counter++;
        if (counter % 4 === 0) {
          counter = 1;
          stringWithSeparator = separator + val.charAt(i - 1) + stringWithSeparator;
        } else {
          stringWithSeparator = val.charAt(i - 1) + stringWithSeparator;
        }
      }
      return stringWithSeparator.trim();
    },
  },
  data() {
    return {
      todayDate: new Date(),
      todayDateString: moment().format("DD/MM/YYYY HH:MM"),
    };
  },

  directives: {
    "click-outside": {
      //@ts-ignore
      bind: function(el, binding, vnode) {
        //@ts-ignore
        el.clickOutsideEvent = function(event) {
          if (!(el == event.target || el.contains(event.target))) {
            if (binding.value && typeof binding.value === "function") {
              binding.value();
            }

            vnode.context[binding.arg] = false;
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  methods: {
    getTodayDateTimeString() {
      return moment().format("DD/MM/YYYY HH:mm");
    },
    // PAGINATION
    //@ts-ignore
    addElementAutocomplete(value, currentElements) {
      //@ts-ignore
      const isAlready = currentElements.find((el) => {
        if (el.id === value.id) return el;
      });

      if (!isAlready) currentElements.push(value);
    },

    genericError() {
      notifBus.$emit("show", {
        status: "error",
        //@ts-ignore
        message: this.$t("overall.notifications.error"),
      });
    },
    selectHours() {
      let response = [];
      for (let i = 0; i < 24; i++) {
        let hour = i < 10 ? "0" + i : i;
        response.push({
          name: hour + ":00",
          value: hour + ":00:00",
        });
        response.push({
          name: hour + ":30",
          value: hour + ":30:00",
        });
      }
      return response;
    },
    //@ts-ignore
    generateSelectDate(from, to) {
      let response = [];
      if (from > to) {
        for (let i = from; i > to; i--) {
          response.push({
            name: i,
            value: i,
          });
        }
      } else {
        for (let i = from; i < to; i++) {
          response.push({
            name: i,
            value: i,
          });
        }
      }
      return response;
    },
    //@ts-ignore
    generateTodayDate() {
      //@ts-ignore
      return (
        //@ts-ignore
        this.todayDate.getFullYear() +
        "-" +
        //@ts-ignore
        ("0" + (this.todayDate.getMonth() + 1)).slice(-2) +
        "-" +
        //@ts-ignore
        ("0" + this.todayDate.getDate()).slice(-2)
      );
    },
    //@ts-ignore
    generateDate(day, month, year) {
      //@ts-ignore
      return (
        //@ts-ignore
        year +
        "-" +
        //@ts-ignore
        ("0" + month).slice(-2) +
        "-" +
        //@ts-ignore
        ("0" + day).slice(-2)
      );
    },
    //@ts-ignore
    resolve(path, obj) {
      //@ts-ignore
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    //@ts-ignore
    orderListBy(element, array) {
      const _this = this;
      //@ts-ignore
      array = array.sort(function(a, b) {
        let A = _this.resolve(element, a);
        let B = _this.resolve(element, b);
        //@ts-ignore
        if (_this.sort.name === element && _this.sort.direction === "asc") {
          if (b[element] === null) {
            return -1;
          }
          if (typeof a[element] === "string" && a[element] !== null && typeof b[element] === "string") {
            A = a[element].toUpperCase();
            B = b[element].toUpperCase();
          }
          const C = A;
          A = B;
          B = C;
        }
        return A < B ? -1 : A > B ? 1 : 0;
      });
      //@ts-ignore
      _this.sort.direction = _this.sort.name === element && _this.sort.direction === "asc" ? "desc" : "asc";
      //@ts-ignore
      _this.sort.name = element;
    },

    //@ts-ignore
    generateTodayTime() {
      //@ts-ignore
      return (
        //@ts-ignore
        ("0" + this.todayDate.getHours()).slice(-2) + ":" + (this.todayDate.getMinutes() > 30 ? "00" : "30") + ":00"
      ); // /!\ :00 important
    },

    //@ts-ignore
    parseBeautifulDate(date) {
      const reg = /^([1-2][0-9][0-9][0-9])-(0[1-9]|1[1-2])-([0-2][0-9]|3[0-1])$/;
      if (typeof date === "string" && reg.test(date)) {
        const [year, month, day] = date.split("-").map((val) => parseInt(val));
        // @ts-ignore
        return day + " " + this.$t("overall.month" + month) + " " + year;
      }
      return date;
    },
    //@ts-ignore
    parseLaravelDateTime(dateRaw) {
      const date = moment(dateRaw, "YYYY-MM-DD HH:mm:ss");
      // @ts-ignore
      return !date.isValid() ? "" : date.utcOffset(4).format("DD/MM/YYYY HH:mm");
    },
    //@ts-ignore
    formatFullname(item) {
      return item.first_name + " " + item.last_name;
    },
    ...mapActions(["array_to_select"]),
  },

  computed: {
    ...mapState({
      _global: "global",
    }),
  },
  created() {},
};
