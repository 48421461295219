import moment from "moment";
import Vue from "vue";

const ADD_FOLLOW_UP = "ADD_FOLLOW_UP";
const ADD_FOLLOW_UPS = "ADD_FOLLOW_UPS";
const EDIT_FOLLOW_UP = "EDIT_FOLLOW_UP";
const FETCH_FOLLOW_UPS = "FETCH_FOLLOW_UPS";
const EDIT_FOLLOW_UPS_CLIENT = "EDIT_FOLLOW_UPS_CLIENT";
const ADD_STATUS = "ADD_STATUS";

import { AxiosPlugin } from "@/plugins/axios";

Vue.use(AxiosPlugin);
const v = new Vue();

// TODO: continue temporary followUp

// initial state
const state = {
  followUps: [],
  status: [],
};

// getters
const getters = {
  //@ts-ignore
  followUps: (state) => state.followUps,
  //@ts-ignore
  status: (state) => state.status,
};

// actions
const actions = {
  editFollowUpClient({ commit, state }: any, { client }: any) {
    commit(EDIT_FOLLOW_UPS_CLIENT, client);
  },
  async fetchFollowUps({ commit, state }: any, { from }: { from?: Number }): Promise<any> {
    const fromStr: string = typeof from === "number" ? from.toString() : "";
    //@ts-ignore
    const response = await v.$axios.get(`/api/follow-ups?from=${fromStr}`);
    if (response.status === 200) {
      let followUps = response.data.items;
      // @ts-ignore
      followUps = followUps.map((item) => {
        item.come_over_date = item.come_over_date ? item.come_over_date : "";
        return item;
      });
      commit(ADD_FOLLOW_UPS, followUps);
    } else if (response.status === 429) {
      console.log("Error 429", response);
    } else {
      console.log("Fetch followUps error", response.status);
    }
  },
  async fetchStatus({ commit, state }: any): Promise<any> {
    //@ts-ignore
    const response = await v.$axios.get("/api/clients-status");
    if (response.status === 200) {
      let items = response.data.items;
      commit(ADD_STATUS, items);
    } else if (response.status === 429) {
      console.log("Error 429", response);
    } else {
      console.log("Fetch client status list error", response.status);
    }
  },
  async saveFollowUp({ commit, state }: any, { followUp }: any): Promise<any> {
    //@ts-ignore
    return await v.$axios.post("/api/followUps", {
      followUp: followUp,
    });
  },
  async deleteFollowUp({ commit, state }: any, { token }: any): Promise<any> {
    //@ts-ignore
    const response = await v.$axios.delete("/api/followUps/" + token);
    return response;
  },
};

const mutations = {
  //@ts-ignore
  [EDIT_FOLLOW_UPS_CLIENT](state, client) {
    //@ts-ignore
    const followUps = state.followUps.map((followUp) => {
      if (followUp.token === client.token) {
        followUp = { ...followUp, ...client };
      }
      return followUp;
    });
    state.followUps = followUps;
  },
  //@ts-ignore
  [ADD_FOLLOW_UP](state, followUp) {
    state.followUps.push(followUp);
  },
  //@ts-ignore
  [ADD_FOLLOW_UPS](state, followUps) {
    state.followUps = followUps;
  },
  //@ts-ignore
  [ADD_STATUS](state, status) {
    state.status = status;
  },
  //@ts-ignore
  [EDIT_FOLLOW_UP](state, followUp) {
    //@ts-ignore
    let index = state.followUps.findIndex(({ id }) => id === followUp.id);
    if (index > -1) {
      state.followUps.splice(index, 1, { ...state.followUps[index], ...followUp });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
