import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import properties from "./modules/properties";
import agenda from "./modules/agenda";
import followUps from "./modules/followUps";
import searchCriteriaOptions from "./modules/searchCriteriaOptions";
import users from "./modules/users";

Vue.use(Vuex);

const store = {
  state,
  mutations,
  getters,
  actions,
  modules: {
    properties,
    agenda,
    followUps,
    searchCriteriaOptions,
    users,
  },
};
export default new Vuex.Store(store);
