<template>
  <div class="select multi-select" v-click-outside:isOpen>
    <span class="selected" @click="toggleOptions" v-if="elementSelected > 0"
      >{{ elementSelected }} {{ elementSelected > 1 ? $t("overall.element", "", true) : $t("overall.element") }}</span
    >
    <span class="selected" @click="toggleOptions" v-if="elementSelected === 0">{{ placeholder }}</span>
    <ul class="options" v-show="isOpen">
      <li @click="reset" v-if="isReset">{{ $t("overall.reset") }}</li>
      <li
        :class="value.includes(o.value) ? 'is-selected' : ''"
        v-for="(o, i) in options"
        :key="o + i"
        @click="changeValue(o)"
      >
        <span>{{ o.name }}</span>
        <Checkbox :value="value.includes(o.value)" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MultiSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    selectDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
    isReset: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Array,
    },
  },
  watch: {
    deep: true,
    immediate: true,
    value: {
      handler: function(val) {
        if (!val) {
          this.$emit("input", []);
        }
        this.elementSelected = val ? val.length : 0;
      },
    },
  },
  data() {
    return {
      elementSelected: 0,
      lock: 0,
      isUniqueAtm: false,
      isOpen: false,
    };
  },
  methods: {
    reset() {
      this.$emit("input", []);
    },
    toggleOptions() {
      this.isOpen = !this.isOpen;
    },
    changeValue(val = null) {
      let currentValue = this.value;
      if (val) {
        const unique = val.unique !== undefined && val.unique;
        if (this.isUniqueAtm) {
          currentValue = [];
        }

        this.isUniqueAtm = unique;

        if (this.value.includes(val.value)) {
          currentValue = unique
            ? []
            : currentValue.filter((el) => {
                return el !== val.value;
              });
        } else {
          unique ? (currentValue = [val.value]) : currentValue.push(val.value);
        }

        this.$emit("input", currentValue);
      }
    },
  },
};
</script>
