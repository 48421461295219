<template>
    <div class="tag-list">
      <button v-for="(item, index) in items" :key="index" class="tag" :class="{'is-selected': isSelected(item)}" @click="toggle(item)">{{ item[propForText] }} {{isSelected(item) ? 'x' : '+'}}</button>
    </div>
</template>

<script>
export default {
  props: {
    items: {
      type: [Array],
      required: true,
    },
    initialSelectedItems: {
      type: [Array],
      required: false,
      default: () => []
    },
    propForText: {
      type: [String],
      required: false,
      default: "name",
    },
    propForComparison: {
      type: [String],
      required: false,
      default: "id",
    },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  methods: {
    isSelected(item) {
      return this.selectedItems.findIndex((selEl) => selEl[this.propForComparison] === item[this.propForComparison]) >= 0;
    },
    toggle(item) {
      const foundIndex = this.selectedItems.findIndex(
        (selEl) => selEl[this.propForComparison] === item[this.propForComparison],
      );
      if (foundIndex >= 0) {
        this.selectedItems.splice(foundIndex, 1);
      } else {
        this.selectedItems.push(item);
      }
      this.$emit("change", { selectedItems: this.selectedItems });
    },
  },
  watch: {
    initialSelectedItems(value, oldValue) {
      if (value && value !== oldValue) {
        this.selectedItems = value;
      }
    }
  },
  created(){
    this.selectedItems = [...this.initialSelectedItems];
  }
};
</script>

<style scoped lang="scss"></style>
