export default {
  // FRENCH
  properties: "Las propiedades",
  create: "Crear una propiedad",
  modify: "Cambiar una propiedad",
  property: "Ficha de propiedad",
  back: "Las Propiedades",
  no_property: "Sin propiedades",
  title: {
    title: "Titulo de la propiedad",
    placeholder: "Añadir un titulo",
  },
  is_activated: "Activado",
  description: {
    title: "Descripción de propiedad",
    placeholder: "Añadir una descripción",
  },
  type: {
    title: "Tipos de propiedad",
    villa: "Villa",
    flat: "Apartamiento",
    house: "Casa",
    office: "Oficina",
    land: "Terreno",
    garage: "Garaje",
    bungalow: "Bungalow",
    duplex: "Duplex",
    professional_office: "Local comercial",
    building: "Edificio",
    industrial_land: "Terreno industrial",
    agricultural_land: "Finca",
    penthouse: "Ático",
  },
  status: {
    title: "Estado",
    sell: "En venta",
    rent: "En alquiler",
    sold: "Vendido",
    booked: "Guardado",
    stock_in_trade: "Traspasso",
  },
  availability: "Disponibilidad",

  price: "Precio",

  // Box content
  labels: {
    specifications: "Características",
    options: "Opciones",
    platforms: "Portales",
  },
  construction: {
    title: "Construcción",
    new: "Nueva",
    used: "Segunda mano",
    reworked: "Reformada",
  },
  indoor_area: "Área habitable",
  land_area: "Área de terreno",
  total_area: "Área total",
  bedrooms: "Dormitórios",
  bathrooms: "Baños",
  swimming_pool: {
    title: "Piscina",
    community: "Comunaitária",
    private: "Privada",
  },
  car_park: {
    title: "Parking",
    garage: "Garaje Privado",
    public: "Publico",
    private: "Plaza Privada",
  },
  parking: "Garaje",
  furniture: "Amueblado",
  disabled_access: "Aceso a descapacitados",
  beach_distance: "Distancia de playa",
  sun_orientation: "Orientación del sol",
  charges: "Gastos communitarios",
  land_register: "IBI",

  // TO ADD
  terrace: "Tarraza",
  patio: "Patio",
  terrace_area: "Área de terraza",
  patio_area: "Área de patio",
  energy_performance: "Certificado energético",
  sea_sight: "Vista al mar",
  shops_distance: "Distancia de comercios",
  floor: {
    title: "Planta",
    ground_floor: "Bajo",
  },
  in_golf: "Golf",

  // OPTIONS
  air_conditioning: "Aire acondicionado",
  solarium: "Solario",
  elevator: "Ascensor",
  wifi: "Wifi",
  spa: "Spa",
  fitness: "Gimnasio",
  animals: {
    title: "Animales",
    allowed: "Admitidos",
    not_allowed: "Proibidos",
  },

  // RIGHT PANEL
  no_image: "No ay imagenes",
  manage_images: "Gestionar las imagenes",
  reference: "Referencia",

  //TAGS
  tags: "Etiquetas",
  new_price: "Nuevo precio ",
  opportunity: "Oportunidad",
  new_property: "Novedad",

  address: {
    title: "Dirección",
    street: "Calle",
    number: "Numero",
    postcode: "Código Postal",
    city: "Ciudad",
    region: "Zona",
    province: "Província",
    country: "Pays",
    placeholder: "Ex: Calle del sol, 9",
  },
  library: {
    title: "Gestión de fotos",
    add_picture: "Añadir una foto",
    no_picture: "¡No ay fotos!",
    drag_and_drop: "Deslize sus ficheros para añadirlos",
    images: "Imagen",
    videos: "Videos",
  },

  notification: {
    created: "Propiedad creada",
    updated: "Cambios registrados",
    deleted: "Propriedade borrada",
  },
  // PROPERTIES
  name: "nombre",
  location: "ubicación",
  main_specs: "Características principales",
  second_specs: "Características segundárias",
  associated_key: "Llave associada",
  key_name: "N° de llave",
  partner: "Colaborador",

  confirm_delete: "¿Está seguro de querer borrar esta propiedad ?",
  loading_properties: "Cargando propiedades...",
  platforms: "Platformas",
  warning_property_deactivated: "Esta propiedad se encuentra desactivada",
};
