import overall from "./overall";
import properties from "./properties";
import clients from "./clients";
import projects from "./projects";
import builders from "./builders";
import settings from "./settings";
import login from "./login";

export default {
  overall,
  properties,
  clients,
  projects,
  builders,
  settings,
  login,
};
