import _Vue from "vue";

import translations from "@/localization";

const pluralSeparator = "||";

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function LocalizationPlugin(Vue: typeof _Vue, options?: LocalizationPluginOptions): void {
  Vue.prototype.$t = (value: string, replace: object = {}, plural: boolean = false) => {
    const path = value.split(".");
    let lang = _Vue.$cookies.get("usr_lang") ? _Vue.$cookies.get("usr_lang") : "en";
    if (!translations.hasOwnProperty(lang)) {
      lang = "en";
    }
    lang = "en";

    // @ts-ignore
    let stack: object | string = translations[lang];

    path.forEach((p: string) => {
      if (stack.hasOwnProperty(p)) {
        //@ts-ignore
        stack = stack[p];
      }
    });

    if (typeof stack === "string") {
      //@ts-ignore
      const spliceIndex = stack.indexOf(pluralSeparator);
      if (spliceIndex !== -1) {
        //@ts-ignore
        stack = (plural ? stack.substring(spliceIndex + 2, stack.length) : stack.substring(0, spliceIndex)).trim();
      }

      // check whether there is a replace
      if (Object.entries(replace).length) {
        for (const [key, value] of Object.entries(replace)) {
          //@ts-ignore
          stack = stack.replace("{" + key + "}", value);
        }
      }
      return stack;
    }
    return value;
  };
}

export class LocalizationPluginOptions {
  // add stuff
}
