<template>
  <div class="inner-wrapper add-project-popup">
    <form @submit.prevent="saveProject">
      <h2>{{ $t("projects.project_name") }}</h2>
      <div class="input-box">
        <input type="text" v-model="project.name" :placeholder="$t('overall.name')" />
      </div>
      <div class="flex main-actions">
        <button class="cta">{{ $t("projects.create") }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import notifBus from "@/bus/notification-bus";
import modalBus from "@/bus/modal-bus";

export default Vue.extend({
  data() {
    return {
      project: {
        id: null,
        token: null,
        name: "",
        start_date: null,
        end_date: null,
        builder: null,
        address: null,
        partner: null,
      },
    };
  },
  methods: {
    saveProject() {
      this.$axios
        .post("/api/projects", {
          project: this.project,
        })
        .then((res) => {
          modalBus.$emit("toggle");
          this.$router.push({ name: "edit-project", params: { token: res.data.token } });
        })
        .catch((res) => {
          notifBus.$emit("show", {
            status: "error",
            message: "Something went wrong",
          });
        });
    },
  },
});
</script>
