import { Capability } from "@/types";

/**
 * @param capabilities
 * @param neededCapabilities only one of those are required to be valid
 */
export default (capabilities: Array<Capability>, neededCapabilities: Array<Capability>): Boolean => {
  let can: Boolean = false;
  neededCapabilities.forEach((capability: Capability) => {
    if (capabilities.includes(capability)) {
      can = true;
    }
  });
  return can;
}