<template>
  <input type="time" v-bind:value="valueCloned" v-on:change="onChange" />
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: [String],
      required: true,
    },
  },
  data() {
    return {
      valueCloned: "",
    };
  },
  methods: {
    removeSecondsFromTime(string) {
      return moment(string, "HH:mm").format("HH:mm");
    },
    onChange(event) {
      console.log("do something 2");
      const value = event.target.value;
      this.valueCloned = this.removeSecondsFromTime(value);
      this.$emit("input", this.valueCloned);
    },
  },
  created() {
    let value = this.value + "";
    if (this.value && this.value !== "") {
      value = this.removeSecondsFromTime(value);
    }
    this.valueCloned = value;
  },
};
</script>

<style scoped lang="scss"></style>
