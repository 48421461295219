export default {
  month1: "January",
  month2: "February",
  month3: "March",
  month4: "April",
  month5: "May",
  month6: "June",
  month7: "July",
  month8: "August",
  month9: "September",
  month10: "October",
  month11: "November",
  month12: "December",

  lang: {
    en: "English",
    fr: "French",
    it: "Italian",
    de: "German",
    es: "Spanish",
    nl: "Dutch",
    pt: "Portuguese",
    ru: "Russian",
    pl: "Polish",
    no: "Norwegian",
    sw: "Swedish",
    bg: "Bulgarian",
    da: "Danish",
    cs: "Czech",
    fi: "Finnish",
    el: "Greek",
  },

  day1: "Monday",
  day2: "Tuesday",
  day3: "Wednesday",
  day4: "Thursday",
  day5: "Friday",
  day6: "Saturday",
  day7: "Sunday",

  date: "Date",

  today: "Today",
  day: "day",
  week: "week",
  month: "month",
  year: "year",

  filter: "Filter",
  filters: "Filters",
  edit: "Edit",
  create: "Create",
  update: "Update",
  new: "New",
  search: "Search",
  "back-to-the-list": "Back to the list",
  results: "results",
  per_page: "per page",
  create_new: "Create new",
  save: "Save",
  cancel: "Cancel",
  add: "Add",
  delete: "Delete",
  actions: "Actions",
  reinitialize: "Reinitialize",
  import: "Import",
  export: "Export",
  send: "Send",
  print: "Print",
  duplicate: "Duplicate",
  confirm: "Confirm",
  select_all: "Select all",
  confirmation: "Confirmation",
  pending: "Pending",

  are_you_sure: "Are you sure ?",
  not_specified: "Not specified",
  yes: "Yes",
  no: "No",
  none: "None",

  east: "East",
  west: "West",
  north: "North",
  south: "South",

  languages: "Languages",
  french: "French",
  english: "English",
  spanish: "Spanish",
  german: "German",
  italian: "Italian",
  dutch: "Dutch",
  russian: "Russian",
  swedish: "Swedish",
  norwegian: "Norwegian",
  bulgarian: "Bulgarian",
  polish: "Polish",
  finnish: "Finnish",
  czech: "Czech",
  greek: "Greek",
  danish: "Danish",
  portuguese: "Portuguese",

  country: "Country",
  france: "France",
  belgium: "Belgium",
  england: "England",
  italy: "Italy",
  spain: "Spain",
  germany: "Germany",
  netherlands: "Netherlands",
  russia: "Russia",
  sweden: "Sweden",
  norway: "Norway",
  portugal: "Portugal",

  infodays: "Infodays",
  news: "News",
  jobs: "Jobs",

  admin: "Administrator",
  agent: "Agent",
  manager: "Manager",
  agent_collaborator: "Agent Collaborator",
  agent_uniq: "Agent Uniq",
  telesales: "Telesales",
  office_account: "Office Account",

  name: "Name",
  username: "Username",
  last_name: "Last name",
  first_name: "First name",
  reference: "Reference",
  company: "Company",
  birthday: "Birthday",
  client_number: "Client number",
  status: "Status",
  email: "Email",
  password: "Password",
  timezone: "Timezone",
  phone: "Phone",
  id_number: "ID Number",
  sex: "Sex",
  man: "Man",
  woman: "Woman",
  address: "Address",
  location: "Location",
  street: "Street",
  number: "Number",
  city: "City",
  postcode: "Postal Code",
  optional: "Optional",
  tasks: "Tasks",
  login: "Login",
  signup: "Signup",
  logout: "Logout",
  reset: "Reset",
  source: "Source",
  element: "Element || Elements",
  rank: "Rank",

  photos: "photos",
  dashboard: "Dashboard",
  calendar: "Calendar",
  blog: "Blog",
  real_estate: "Real Estate",
  clients: "Clients",
  clients_follow_up: "Clients follow up",
  users: "Users",
  editor: "Editor",
  settings: "Settings",
  analytics: "Analytics",
  library: "Library",
  note: "Note",
  title: "Title",
  previous: "Previous",
  next: "Next",
  builder: "Builder",
  builders: "Builders",
  projects: "Projects",
  phase: "Phase",
  click_to_add_date: "Add date",

  others: "Others",
  other: "Other",
  start_date: "Starting date",
  completion_date: "Completion date",
  website: "Website",
  language: "Language",
  no_result: "No result",
  add_date: "Add date",
  add_address: "Add address",

  address_components: {
    title: "Address",
    street: "Street",
    number: "Number",
    postcode: "Postcode",
    city: "City",
    region: "Region",
    province: "Province",
    country: "Country",
    placeholder: "Ex: Rue d'Andrimont 108, Barchon, Belgique",
  },
  public: "Public",
  private: "Private",
  community: "Community",

  general_information: "General Information",
  information: "Information",
  social_media: "Social Media",
  preferences: "Preferences",
  company_name: "Company name",
  logo: "Logo",
  from: "From",
  to: "To",
  pick_date: "Pick a date",
  add_title: "Add title",
  visit: "Visit",
  appointment: "Appointment",
  task: "Task",
  deal: "Deal",
  profile: "Profile",
  properties: "Properties",

  notifications: {
    error: "A general error occured",
  },
};
