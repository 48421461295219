










































































import calendarSVG from "../assets/img/calendar.svg";
import menuSVG from "../assets/img/menu.svg";
import dashboardSVG from "../assets/img/dashboard.svg";
import propertiesSVG from "../assets/img/properties.svg";
import infoSVG from "../assets/img/info.svg";
import clientsSVG from "../assets/img/clients.svg";
import userSVG from "../assets/img/user.svg";
import builderSVG from "../assets/img/builder.svg";
import settingsSVG from "../assets/img/settings.svg";
import projectSVG from "../assets/img/project.svg";
import newsSVG from "../assets/img/news.svg";
import modalBus from "@/bus/modal-bus";
import { routes } from "@/router/routes";

import Vue from "vue";
import { Capability } from "@/types";
import userCan from "@/helpers/userCan";

export default Vue.extend({
  name: "Menu",
  methods: {
    modalSettings() {
      modalBus.$emit("change", {
        component: "Settings",
      });
      modalBus.$emit("set-size", {
        width: "700px",
        height: "auto",
        "margin-top": "50px",
      });

      modalBus.$emit("toggle");
    },
    logout() {
      this.$cookies.remove("jkt");
      this.$router.push({ name: "login" });
      this.$store.commit("TOGGLE_MENU", false);
    },
    routeIsAccessible(routeName: any): Boolean {
      // @ts-ignore
      if (!this.userCapabilities) {
        return false;
      }
      const capabilities: Array<Capability> | undefined = this.$router.resolve({
        name: routeName,
      })?.resolved?.meta?.capabilities;
      let show: Boolean = false;
      if (capabilities) {
        // @ts-ignore
        show = userCan(this.userCapabilities, capabilities);
      }
      return show;
    },
  },
  computed: {
    user() {
      // @ts-ignore
      return this.$store.state.global.user;
    },
    userCapabilities() {
      // @ts-ignore
      return this.user && this.user.capabilities ? this.user.capabilities : [];
    },
  },
  watch: {
    $cookies: {
      handler() {
        // @ts-ignore
        this.isOpen = this.$cookies.get("jkt") !== undefined;
      },
      deep: true,
    },
  },
  data() {
    return {
      isOpen: this.$cookies.get("jkt") !== undefined,
      showFooter: false,
    };
  },
  created() {
    //this.$store.commit("TOGGLE_MENU",true);
  },
  components: {
    calendarSVG,
    menuSVG,
    dashboardSVG,
    propertiesSVG,
    clientsSVG,
    newsSVG,
    userSVG,
    infoSVG,
    settingsSVG,
    builderSVG,
    projectSVG,
  },
});
